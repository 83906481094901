import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
} from "@chakra-ui/modal";
import { updateGateway } from "api/gateways";
import Card from "components/card";
import Checkbox from "components/checkbox";
import DivLoader from "components/divloader/DivLoader";
import InputField from "components/fields/InputField";
import { toast } from "react-hot-toast";
import { BsPlusCircle } from "react-icons/bs";
import { MdEdit, MdInfo } from "react-icons/md";
import React, { useEffect, useRef } from "react";
import cardTypes from "./cardTypes";
import { MultiSelect } from "react-multi-select-component";
import { BiBorderRadius } from "react-icons/bi";

const CreatePaymentMethoddModal = ({
  fetchUsers,
  id,
  is_info,
  data,
  roleData,
  timeZone,
  allCardType,
}: {
  fetchUsers: () => void;
  id?: string;
  is_info?: boolean;
  data?: any;
  roleData?: any;
  timeZone?: any;
  allCardType?: any;
}) => {
  const rootFormdata = {
    Sipe: {
      name: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      business_name: "",
      app_id: "",
      body: "",
      key: "",
      merchant_id: "",
      nonce_string: "",
      notify_url: "",
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Stripe: {
      name: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      secret_key: "",
      statement_descriptor: "",
      statement_descriptor_suffix: "",
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Pix: {
      name: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      // business_name: "",
      email_test: "",
      name_test: "",
      // subscription_key: "",
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Blumon: {
      name: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      business_name: "",
      password: "",
      username: "",
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    pixlotus: {
      name: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      authorization_name: "",
      base_url: "",
      subscription_key: "",
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Memphis: {
      name: "",
      tkr_test: "",
      commerce_name_test: "",
      commerce_id_test: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Raypd: {
      name: "",
      secret_key_test: "",
      access_key_test: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Banwire: {
      name: "",
      username_test: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Valitor: {
      name: "",
      apikey_test: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Bambora: {
      name: "",
      merchantId_test: "",
      passcode_test: "",
      hash_key_test: "",
      recurring_passcode_test : "",
      reporting_passcode_test : "",
      batch_passcode_test : "",
      subscription_passcode_test : "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    reSet: {
      name: "",
      secretKey_test: "",
      brand_id_test: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    MIT: {
      name: "",
      id_branch_test: "",
      id_company_test: "",
      merchant_id_test: "",
      password_test: "",
      user_code_test: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Scipiopay: {
      name: "",
      shop_id_test: "",
      shop_secret_key_test: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    "Aurea Via": {
      name: "",
      companyNum_test: "",
      personalHashkey_test: "",
      // shop_secret_key_test: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      test_endpoint: "",
      live_endpoint: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Betapay: {
      name: "",
      api_token_test: "",
      merchant_id_test: "",
      terminal_id_test: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Kasha: {
      name: "",
      api_key_test: "",
      merchant_domain_test: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Linx: {
      name: "",
      client_id_test: "",
      client_secret_test: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Supefina: {
      name: "",
      marechant_id: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Neonpay: {
      name: "",
      merchant_key_test: "",
      password_test: "",
      // term_url_3ds_test: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
    Bm2pay: {
      name: "",
      client_id_test: "",
      client_secret_test: "",
      payments: false,
      authorization: false,
      subscription: false,
      payout: false,
      refund: false,
      direct_debit: false,
      payin: false,
      token: false,
      is_active: false,
      timezone: "",
      payout_cronjob_sleep_time: "",
      payment_cronjob_sleep_time: "",
      is_payment_cronjob_enabled: true,
      transaction_status_enabled_by : 2,
      is_payout_cronjob_enabled: false,
    },
  };

  const rootForm =
    id === "Sipe"
      ? rootFormdata?.Sipe
      : id === "Stripe"
        ? rootFormdata?.Stripe
        : id === "Pix"
          ? rootFormdata?.Pix
          : id === "Blumon"
            ? rootFormdata?.Blumon
            : id === "pixlotus"
              ? rootFormdata?.pixlotus
              : id === "Memphis"
                ? rootFormdata?.Memphis
                : id === "Raypd"
                  ? rootFormdata?.Raypd
                  : id === "Banwire"
                    ? rootFormdata?.Banwire
                    : id === "Valitor"
                      ? rootFormdata?.Valitor
                      : id === "Bambora"
                        ? rootFormdata?.Bambora
                        : id === "MIT"
                          ? rootFormdata?.MIT
                          : id === "Scipiopay"
                            ? rootFormdata?.Scipiopay
                            : id === "Aurea Via"
                              ? rootFormdata["Aurea Via"]
                              : id === "Betapay"
                                ? rootFormdata?.Betapay
                                : id === "Kasha"
                                  ? rootFormdata?.Kasha
                                  : id === "Linx"
                                    ? rootFormdata?.Linx
                                    : id === "Supefina"
                                      ? rootFormdata?.Supefina
                                      : id === "Neonpay"
                                        ? rootFormdata?.Neonpay
                                        : id === "Bm2pay"
                                        ? rootFormdata?.Bm2pay
                                        : null;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [formValues, setFormValues] = React.useState<any>(rootForm);
  const [viewMode, setViewMode] = React.useState<boolean>(is_info);
  const [formValuesErr, setFormValuesErr] = React.useState<any>(rootForm);
  const [showCards, setShowCards] = React.useState<boolean>(false);
  const [checkedCards, setCheckedCards] = React.useState<any[]>([]);
  const [isFormInitialized, setIsFormInitialized] = React.useState(false);
  const [endpoints, setEndpoints] = React.useState({
    test: "",
    live: "",
  });

  //// console.log("allCardType", timeZone);
  const initForm = () => {
    if (data && !isFormInitialized) {      
      const newFormValues =
        data?.name === "Pix"
          ? {
            name: data?.name,
            payments: data?.payments,
            authorization: data?.authorization,
            subscription: data?.subscription,
            card_types: data?.card_types,
            payout: data?.payout,
            refund: data?.refund,
            apm: data?.apm,
            direct_debit: data?.direct_debit,
            payin: data?.payin,
            token: data?.token,
            is_active: data?.is_active,
            timezone: data?.timezone,
            id: data?.id,
            // subscription_key: data?.meta_info?.subscription_key,
            // business_name: data?.meta_info?.business_name,

            name_test: data?.meta_info?.name_test,
            email_test: data?.meta_info?.email_test,
            test_endpoint: data?.test_endpoint,
            live_endpoint: data?.live_endpoint,
            payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
            payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
            is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
            is_payout_cronjob_enabled: data.is_payout_cronjob_enabled,
            webhook_enabled: data.webhook_enabled,
            webhook_url: data.webhook_url,
            transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
          }
          : data?.name === "Pix-Lotus"
            ? {
              name: data?.name,
              payments: data?.payments,
              authorization: data?.authorization,
              subscription: data?.subscription,
              card_types: data?.card_types,
              payout: data?.payout,
              refund: data?.refund,
              apm: data?.apm,
              direct_debit: data?.direct_debit,
              payin: data?.payin,
              token: data?.token,
              is_active: data?.is_active,
              timezone: data?.timezone,
              id: data?.id,
              authorization_name: data?.meta_info?.authorization,
              base_url: data?.meta_info?.base_url,
              subscription_key: data?.meta_info?.subscription_key,
              test_endpoint: data?.test_endpoint,
              live_endpoint: data?.live_endpoint,
              payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
              payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
              is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
              is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
              webhook_url: data.webhook_url,
              transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
            }
            : data?.name === "Blumon"
              ? {
                name: data?.name,
                payments: data?.payments,
                authorization: data?.authorization,
                subscription: data?.subscription,
                card_types: data?.card_types,
                payout: data?.payout,
                refund: data?.refund,
                apm: data?.apm,

                direct_debit: data?.direct_debit,
                payin: data?.payin,
                token: data?.token,
                is_active: data?.is_active,
                timezone: data?.timezone,
                id: data?.id,
                username: data?.meta_info?.username,
                password: data?.meta_info?.password,
                business_name: data?.meta_info?.business_name,
                test_endpoint: data?.test_endpoint,
                live_endpoint: data?.live_endpoint,
                payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                webhook_url: data.webhook_url,
                transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
              }
              : data?.name === "Stripe"
                ? {
                  name: data?.name,
                  payments: data?.payments,
                  authorization: data?.authorization,
                  subscription: data?.subscription,
                  card_types: data?.card_types,
                  payout: data?.payout,
                  refund: data?.refund,
                  apm: data?.apm,

                  direct_debit: data?.direct_debit,
                  payin: data?.payin,
                  token: data?.token,
                  is_active: data?.is_active,
                  timezone: data?.timezone,
                  id: data?.id,
                  secret_key: data?.meta_info?.secret_key,
                  statement_descriptor_suffix:
                    data?.meta_info?.statement_descriptor_suffix,
                  statement_descriptor: data?.meta_info?.statement_descriptor,
                  test_endpoint: data?.test_endpoint,
                  live_endpoint: data?.live_endpoint,
                  payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                  payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                  is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                  is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                  webhook_url: data.webhook_url,
                  transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                }
                : data?.name === "Sipe"
                  ? {
                    name: data?.name,
                    payments: data?.payments,
                    authorization: data?.authorization,
                    subscription: data?.subscription,
                    card_types: data?.card_types,
                    payout: data?.payout,
                    refund: data?.refund,
                    apm: data?.apm,

                    direct_debit: data?.direct_debit,
                    payin: data?.payin,
                    token: data?.token,
                    is_active: data?.is_active,
                    timezone: data?.timezone,
                    id: data?.id,
                    business_name: data?.meta_info?.business_name,
                    app_id: data?.meta_info?.app_id,
                    body: data?.meta_info?.body,
                    key: data?.meta_info?.key,
                    merchant_id: data?.meta_info?.merchant_id,
                    nonce_string: data?.meta_info?.nonce_string,
                    notify_url: data?.meta_info?.notify_url,
                    test_endpoint: data?.test_endpoint,
                    live_endpoint: data?.live_endpoint,
                    payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                    payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                    is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                    is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                    webhook_url: data.webhook_url,
                    transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                  }
                  : data?.name === "Memphis"
                    ? {
                      name: data?.name,
                      tkr_test: data?.meta_info?.tkr_test,
                      commerce_name_test: data?.meta_info?.commerce_name_test,
                      commerce_id_test: data?.meta_info?.commerce_id_test,
                      payments: data?.payments,
                      authorization: data?.authorization,
                      subscription: data?.subscription,
                      card_types: data?.card_types,
                      payout: data?.payout,
                      refund: data?.refund,
                      apm: data?.apm,

                      direct_debit: data?.direct_debit,
                      payin: data?.payin,
                      token: data?.token,
                      is_active: data?.is_active,
                      timezone: data?.timezone,
                      id: data?.id,
                      test_endpoint: data?.test_endpoint,
                      live_endpoint: data?.live_endpoint,
                      payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                      payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                      is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                      is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                      webhook_url: data.webhook_url,
                      transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                    }
                    : data?.name === "Raypd"
                      ? {
                        name: data?.name,
                        id: data?.id,
                        is_active: data?.is_active,
                        timezone: data?.timezone,
                        payments: data?.payments,
                        authorization: data?.authorization,
                        subscription: data?.subscription,
                        card_types: data?.card_types,
                        payout: data?.payout,
                        refund: data?.refund,
                        apm: data?.apm,

                        direct_debit: data?.direct_debit,
                        payin: data?.payin,
                        token: data?.token,
                        access_key_test: data?.meta_info?.access_key_test,
                        secret_key_test: data?.meta_info?.secret_key_test,
                        test_endpoint: data?.test_endpoint,
                        live_endpoint: data?.live_endpoint,
                        payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                        payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                        is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                        is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                        webhook_url: data.webhook_url,
                        transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                      }
                      : data?.name === "Banwire"
                        ? {
                          name: data?.name,
                          id: data?.id,
                          is_active: data?.is_active,
                          timezone: data?.timezone,
                          payments: data?.payments,
                          authorization: data?.authorization,
                          subscription: data?.subscription,
                          card_types: data?.card_types,
                          payout: data?.payout,
                          refund: data?.refund,
                          apm: data?.apm,

                          direct_debit: data?.direct_debit,
                          payin: data?.payin,
                          token: data?.token,
                          username_test: data?.meta_info?.username_test,
                          test_endpoint: data?.test_endpoint,
                          live_endpoint: data?.live_endpoint,
                          payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                          payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                          is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                          is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                          webhook_url: data.webhook_url,
                          transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                        }
                        : data?.name === "Valitor"
                          ? {
                            name: data?.name,
                            id: data?.id,
                            is_active: data?.is_active,
                            timezone: data?.timezone,
                            payments: data?.payments,
                            authorization: data?.authorization,
                            subscription: data?.subscription,
                            card_types: data?.card_types,
                            payout: data?.payout,
                            refund: data?.refund,
                            apm: data?.apm,

                            direct_debit: data?.direct_debit,
                            payin: data?.payin,
                            token: data?.token,
                            apikey_test: data?.meta_info?.apikey_test,
                            test_endpoint: data?.test_endpoint,
                            live_endpoint: data?.live_endpoint,
                            payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                            payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                            is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                            is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                            webhook_url: data.webhook_url,
                            transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                          }
                          : data?.name === "Bambora"
                            ? {
                              name: data?.name,
                              id: data?.id,
                              is_active: data?.is_active,
                              timezone: data?.timezone,
                              payments: data?.payments,
                              authorization: data?.authorization,
                              subscription: data?.subscription,
                              card_types: data?.card_types,
                              payout: data?.payout,
                              refund: data?.refund,
                              apm: data?.apm,

                              direct_debit: data?.direct_debit,
                              payin: data?.payin,
                              token: data?.token,
                              merchantId_test: data?.meta_info?.merchantId_test,
                              passcode_test: data?.meta_info?.passcode_test,
                              hash_key_test: data?.meta_info?.hash_key_test,
                              recurring_passcode_test: data?.meta_info?.recurring_passcode_test,
                              reporting_passcode_test: data?.meta_info?.reporting_passcode_test,
                              batch_passcode_test: data?.meta_info?.batch_passcode_test,
                              subscription_passcode_test: data?.meta_info?.subscription_passcode_test,
                              test_endpoint: data?.test_endpoint,
                              live_endpoint: data?.live_endpoint,
                              payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                              payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                              is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                              is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                              webhook_url: data.webhook_url,
                              transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                            }
                            : data?.name === "Re-set"
                              ? {
                                name: data?.name,
                                id: data?.id,
                                is_active: data?.is_active,
                                timezone: data?.timezone,
                                payments: data?.payments,
                                authorization: data?.authorization,
                                subscription: data?.subscription,
                                card_types: data?.card_types,
                                payout: data?.payout,
                                refund: data?.refund,
                                apm: data?.apm,

                                direct_debit: data?.direct_debit,
                                payin: data?.payin,
                                token: data?.token,
                                secretKey_test: data?.meta_info?.secretKey_test,
                                brand_id_test: data?.meta_info?.brand_id_test,
                                test_endpoint: data?.test_endpoint,
                                live_endpoint: data?.live_endpoint,
                                payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                webhook_url: data.webhook_url,
                                transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                              }
                              : data?.name === "MIT"
                                ? {
                                  name: data?.name,
                                  id: data?.id,
                                  is_active: data?.is_active,
                                  timezone: data?.timezone,
                                  payments: data?.payments,
                                  authorization: data?.authorization,
                                  subscription: data?.subscription,
                                  card_types: data?.card_types,
                                  payout: data?.payout,
                                  refund: data?.refund,
                                  apm: data?.apm,

                                  direct_debit: data?.direct_debit,
                                  payin: data?.payin,
                                  token: data?.token,
                                  id_branch_test: data?.meta_info?.id_branch_test,
                                  id_company_test: data?.meta_info?.id_company_test,
                                  merchant_id_test: data?.meta_info?.merchant_id_test,
                                  password_test: data?.meta_info?.password_test,
                                  user_code_test: data?.meta_info?.user_code_test,
                                  test_endpoint: data?.test_endpoint,
                                  live_endpoint: data?.live_endpoint,
                                  payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                  payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                  is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                  is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                  webhook_url: data.webhook_url,
                                  transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                                }
                                : data?.name === "Scipiopay"
                                  ? {
                                    name: data?.name,
                                    id: data?.id,
                                    is_active: data?.is_active,
                                    timezone: data?.timezone,
                                    payments: data?.payments,
                                    authorization: data?.authorization,
                                    subscription: data?.subscription,
                                    card_types: data?.card_types,
                                    payout: data?.payout,
                                    refund: data?.refund,
                                    apm: data?.apm,

                                    direct_debit: data?.direct_debit,
                                    payin: data?.payin,
                                    token: data?.token,
                                    shop_id_test: data?.meta_info?.shop_id_test,
                                    shop_secret_key_test: data?.meta_info?.shop_secret_key_test,
                                    test_endpoint: data?.test_endpoint,
                                    live_endpoint: data?.live_endpoint,
                                    payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                    payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                    is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                    is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                    webhook_url: data.webhook_url,
                                    transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                                  }
                                  : data?.name === "Aurea Via"
                                    ? {
                                      name: data?.name,
                                      id: data?.id,
                                      is_active: data?.is_active,
                                      timezone: data?.timezone,
                                      payments: data?.payments,
                                      authorization: data?.authorization,
                                      subscription: data?.subscription,
                                      card_types: data?.card_types,
                                      payout: data?.payout,
                                      refund: data?.refund,
                                      apm: data?.apm,

                                      direct_debit: data?.direct_debit,
                                      payin: data?.payin,
                                      token: data?.token,
                                      companyNum_test: data?.meta_info?.companyNum_test,
                                      personalHashkey_test: data?.meta_info?.personalHashkey_test,
                                      test_endpoint: data?.test_endpoint,
                                      live_endpoint: data?.live_endpoint,
                                      payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                      payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                      is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                      is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                      webhook_url: data.webhook_url,
                                      transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by
                                    }
                                    : data?.name === "Betapay"
                                      ? {
                                        name: data?.name,
                                        id: data?.id,
                                        is_active: data?.is_active,
                                        timezone: data?.timezone,
                                        payments: data?.payments,
                                        authorization: data?.authorization,
                                        subscription: data?.subscription,
                                        card_types: data?.card_types,
                                        payout: data?.payout,
                                        refund: data?.refund,
                                        apm: data?.apm,

                                        direct_debit: data?.direct_debit,
                                        payin: data?.payin,
                                        token: data?.token,
                                        api_token_test: data?.meta_info?.api_token_test,
                                        merchant_id_test: data?.meta_info?.merchant_id_test,
                                        terminal_id_test: data?.meta_info?.terminal_id_test,
                                        test_endpoint: data?.test_endpoint,
                                        live_endpoint: data?.live_endpoint,
                                        payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                        payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                        is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                        is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                        webhook_url: data.webhook_url,
                                        transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                                      }
                                      : data?.name === "Kasha"
                                        ? {
                                          name: data?.name,
                                          id: data?.id,
                                          is_active: data?.is_active,
                                          timezone: data?.timezone,
                                          payments: data?.payments,
                                          authorization: data?.authorization,
                                          subscription: data?.subscription,
                                          card_types: data?.card_types,
                                          payout: data?.payout,
                                          refund: data?.refund,
                                          apm: data?.apm,
                                          direct_debit: data?.direct_debit,
                                          payin: data?.payin,
                                          token: data?.token,
                                          api_key_test: data?.meta_info?.api_key_test,
                                          merchant_domain_test: data?.meta_info?.merchant_domain_test,
                                          test_endpoint: data?.test_endpoint,
                                          live_endpoint: data?.live_endpoint,
                                          payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                          payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                          is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                          is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                          webhook_url: data.webhook_url,
                                          transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                                        }
                                        : data?.name === "Linx"
                                          ? {
                                            name: data?.name,
                                            id: data?.id,
                                            is_active: data?.is_active,
                                            timezone: data?.timezone,
                                            payments: data?.payments,
                                            authorization: data?.authorization,
                                            subscription: data?.subscription,
                                            card_types: data?.card_types,
                                            payout: data?.payout,
                                            refund: data?.refund,
                                            apm: data?.apm,
                                            direct_debit: data?.direct_debit,
                                            payin: data?.payin,
                                            token: data?.token,
                                            client_id_test: data?.meta_info?.client_id_test,
                                            client_secret_test: data?.meta_info?.client_secret_test,
                                            test_endpoint: data?.test_endpoint,
                                            live_endpoint: data?.live_endpoint,
                                            payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                            payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                            is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                            is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                            webhook_url: data.webhook_url,
                                            transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                                          }
                                          : data?.name === "Supefina"
                                            ? {
                                              name: data?.name,
                                              id: data?.id,
                                              is_active: data?.is_active,
                                              timezone: data?.timezone,
                                              payments: data?.payments,
                                              authorization: data?.authorization,
                                              subscription: data?.subscription,
                                              card_types: data?.card_types,
                                              payout: data?.payout,
                                              refund: data?.refund,
                                              apm: data?.apm,
                                              direct_debit: data?.direct_debit,
                                              payin: data?.payin,
                                              token: data?.token,
                                              marechant_id: data?.meta_info?.marechant_id,
                                              test_endpoint: data?.test_endpoint,
                                              live_endpoint: data?.live_endpoint,
                                              payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                              payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                              is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                              is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                              webhook_url: data.webhook_url,
                                              transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                                            }
                                            : data?.name === "Platy"
                                              ? {
                                                name: data?.name,
                                                id: data?.id,
                                                is_active: data?.is_active,
                                                timezone: data?.timezone,
                                                payments: data?.payments,
                                                authorization: data?.authorization,
                                                subscription: data?.subscription,
                                                card_types: data?.card_types,
                                                payout: data?.payout,
                                                refund: data?.refund,
                                                apm: data?.apm,
                                                direct_debit: data?.direct_debit,
                                                payin: data?.payin,
                                                token: data?.token,
                                                api_key_test: data?.meta_info?.api_key_test,
                                                password_test: data?.meta_info?.password_test,
                                                platy_api_key_test: data?.meta_info?.platy_api_key_test,
                                                platy_secret_key_test: data?.meta_info?.platy_secret_key_test,
                                                username_test: data?.meta_info?.username_test,
                                                test_endpoint: data?.test_endpoint,
                                                live_endpoint: data?.live_endpoint,
                                                payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                                payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                                is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                                is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                                webhook_url: data.webhook_url,
                                                transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                                              }
                                              : data?.name === "3xpay"
                                              ? {
                                                name: data?.name,
                                                id: data?.id,
                                                is_active: data?.is_active,
                                                timezone: data?.timezone,
                                                payments: data?.payments,
                                                authorization: data?.authorization,
                                                subscription: data?.subscription,
                                                card_types: data?.card_types,
                                                payout: data?.payout,
                                                refund: data?.refund,
                                                apm: data?.apm,
                                                direct_debit: data?.direct_debit,
                                                payin: data?.payin,
                                                token: data?.token,
                                                api_key_test: data?.meta_info?.api_key_test,
                                                api_secret_test: data?.meta_info?.api_secret_test,
                                                test_endpoint: data?.test_endpoint,
                                                live_endpoint: data?.live_endpoint,
                                                payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                                payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                                is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                                is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                                webhook_url: data.webhook_url,
                                                transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                                              }
                                              : data?.name === "Platy 2DS"
                                              ? {
                                                name: data?.name,
                                                id: data?.id,
                                                is_active: data?.is_active,
                                                timezone: data?.timezone,
                                                payments: data?.payments,
                                                authorization: data?.authorization,
                                                subscription: data?.subscription,
                                                card_types: data?.card_types,
                                                payout: data?.payout,
                                                refund: data?.refund,
                                                apm: data?.apm,
                                                direct_debit: data?.direct_debit,
                                                payin: data?.payin,
                                                token: data?.token,
                                                api_key_test: data?.meta_info?.api_key_test,
                                                password_test: data?.meta_info?.password_test,
                                                platy_api_key_test: data?.meta_info?.platy_api_key_test,
                                                platy_secret_key_test: data?.meta_info?.platy_secret_key_test,
                                                username_test: data?.meta_info?.username_test,
                                                test_endpoint: data?.test_endpoint,
                                                live_endpoint: data?.live_endpoint,
                                                payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                                payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                                is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                                is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                                webhook_url: data.webhook_url,
                                                transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                                              }
                                              : data?.name === "Platy 3DS"
                                              ? {
                                                name: data?.name,
                                                id: data?.id,
                                                is_active: data?.is_active,
                                                timezone: data?.timezone,
                                                payments: data?.payments,
                                                authorization: data?.authorization,
                                                subscription: data?.subscription,
                                                card_types: data?.card_types,
                                                payout: data?.payout,
                                                refund: data?.refund,
                                                apm: data?.apm,
                                                direct_debit: data?.direct_debit,
                                                payin: data?.payin,
                                                token: data?.token,
                                                api_key_test: data?.meta_info?.api_key_test,
                                                password_test: data?.meta_info?.password_test,
                                                platy_api_key_test: data?.meta_info?.platy_api_key_test,
                                                platy_secret_key_test: data?.meta_info?.platy_secret_key_test,
                                                username_test: data?.meta_info?.username_test,
                                                test_endpoint: data?.test_endpoint,
                                                live_endpoint: data?.live_endpoint,
                                                payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                                payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                                is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                                is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                                webhook_url: data.webhook_url,
                                                transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                                              }
                                              : data?.name === "Masterpay"
                                                ? {
                                                  name: data?.name,
                                                  id: data?.id,
                                                  is_active: data?.is_active,
                                                  timezone: data?.timezone,
                                                  payments: data?.payments,
                                                  authorization: data?.authorization,
                                                  subscription: data?.subscription,
                                                  card_types: data?.card_types,
                                                  payout: data?.payout,
                                                  refund: data?.refund,
                                                  apm: data?.apm,
                                                  direct_debit: data?.direct_debit,
                                                  payin: data?.payin,
                                                  token: data?.token,
                                                  merchant_private_key_test:
                                                    data?.meta_info?.merchant_private_key_test,
                                                  test_endpoint: data?.test_endpoint,
                                                  live_endpoint: data?.live_endpoint,
                                                  payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                                  payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                                  is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                                  is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                                  webhook_url: data.webhook_url,
                                                  transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                                                }
                                                : data?.name === "Neonpay"
                                                  ? {
                                                    name: data?.name,
                                                    id: data?.id,
                                                    is_active: data?.is_active,
                                                    timezone: data?.timezone,
                                                    payments: data?.payments,
                                                    authorization: data?.authorization,
                                                    subscription: data?.subscription,
                                                    card_types: data?.card_types,
                                                    payout: data?.payout,
                                                    refund: data?.refund,
                                                    apm: data?.apm,
                                                    direct_debit: data?.direct_debit,
                                                    payin: data?.payin,
                                                    token: data?.token,
                                                    merchant_key_test: data?.meta_info?.merchant_key_test,
                                                    password_test: data?.meta_info?.password_test,
                                                    // term_url_3ds_test: data?.meta_info?.term_url_3ds_test || "",
                                                    test_endpoint: data?.test_endpoint,
                                                    live_endpoint: data?.live_endpoint,
                                                    payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                                    payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                                    is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                                    is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data?.webhook_enabled,
                                                    webhook_url: data?.webhook_url,
                                                    transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                                                  }
                                                  : data?.name === "Bm2pay"
                                                    ? {
                                                      name: data?.name,
                                                      id: data?.id,
                                                      is_active: data?.is_active,
                                                      timezone: data?.timezone,
                                                      payments: data?.payments,
                                                      authorization: data?.authorization,
                                                      subscription: data?.subscription,
                                                      card_types: data?.card_types,
                                                      payout: data?.payout,
                                                      refund: data?.refund,
                                                      apm: data?.apm,
                                                      direct_debit: data?.direct_debit,
                                                      payin: data?.payin,
                                                      token: data?.token,
                                                      client_id_test: data?.meta_info?.client_id_test,
                                                      client_secret_test: data?.meta_info?.client_secret_test,
                                                      test_endpoint: data?.test_endpoint,
                                                      live_endpoint: data?.live_endpoint,
                                                      payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                                      payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                                      is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                                      is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                                      webhook_url: data.webhook_url,
                                                      transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                                                    }
                                                    : {
                                                      name: data?.name,
                                                      payments: data?.payments,
                                                      authorization: data?.authorization,
                                                      subscription: data?.subscription,
                                                      card_types: data?.card_types,
                                                      payout: data?.payout,
                                                      refund: data?.refund,
                                                      apm: data?.apm,

                                                      direct_debit: data?.direct_debit,
                                                      payin: data?.payin,
                                                      token: data?.token,
                                                      is_active: data?.is_active,
                                                      timezone: data?.timezone,
                                                      id: data?.id,
                                                      test_endpoint: data?.test_endpoint,
                                                      live_endpoint: data?.live_endpoint,
                                                      payout_cronjob_sleep_time: data.payout_cronjob_sleep_time,
                                                      payment_cronjob_sleep_time: data.payment_cronjob_sleep_time,
                                                      is_payment_cronjob_enabled: data.is_payment_cronjob_enabled,
                                                      is_payout_cronjob_enabled: data.is_payout_cronjob_enabled, webhook_enabled: data.webhook_enabled,
                                                      webhook_url: data.webhook_url,
                                                      transaction_status_enabled_by :(data.transaction_status_enabled_by == "" || data.transaction_status_enabled_by == undefined) ? 2 : data.transaction_status_enabled_by 
                                                    };
      setFormValues(newFormValues);
    }
  };

  const updateCheckedCards = () => {
    const formatCardValues = formValues?.card_types?.map((item: any) => {
      return {
        label: item,
        value: item,
      };
    });
    setCheckedCards(formatCardValues);
    setIsFormInitialized(true);
  };

  React.useEffect(() => {
    initForm();
  }, [data, isFormInitialized]);

  React.useEffect(() => {
    // console.log('form',formValues);
    updateCheckedCards();
  }, [formValues]);
  const handleClose = () => {
    fetchUsers();
    setFormValues(rootForm);
    setFormValuesErr(rootForm);
    onClose();
  };

  const showCardTypes = () => {
    let arr = ["refund", "payments", "subscription"];
    const isAnyCheckboxChecked = arr.some((id) => {
      const checkbox = document.getElementById(id) as HTMLInputElement | null;
      return checkbox ? checkbox.checked : false;
    });
    return setShowCards(isAnyCheckboxChecked);
  };

  function handleValueChange(e: any) {
    setFormValues({
      ...formValues,
      [e.target.id]:
        e.target.value === "true"
          ? true
          : e.target.value === "false"
            ? false
            : e.target.value,
    });

    showCardTypes();
    setFormValuesErr({ ...formValuesErr, [e.target.id]: "" });
  }
  const handleCardChange = (event: any) => {
    const cardType = event;
    setCheckedCards(cardType);
  };

  //// console.log("CheckedCards",formValues);

  function validateData() {
    console.log("inside validation above excape array")

    let escape_arr = [
      "statement_descriptor",
      "statement_descriptor_suffix",
      "business_name",
      "term_url_3ds_test",
      "term_url_3ds_live",
      "term_url_3ds"
    ];

    let verifier: any;
    Object.keys(formValues).forEach((key: any) => {
      let temp_arr = escape_arr.filter((ele) => key === ele);

      if (!(temp_arr?.length > 0)) {
        if (!formValues[key as keyof any]) {
          verifier = { ...verifier, [key]: "Please enter a value" };
        }
      }
    });

    setFormValuesErr(verifier);

    const hasEmptyValues = Object.entries(formValues).some(([key, value]) => {
      let temp_arr_ = escape_arr.filter((ele) => key === ele);
      if (!(temp_arr_?.length > 0)) {
        return value === "";
      }
    });
    if (hasEmptyValues) {
      return false;
    }
    return true;
  }

  async function handleSubmit(e: any) {
    console.log("inside the handle submit")
    e.preventDefault();

    let validate = validateData();
    if (formValues?.is_active ? validate : true) {
      try {
        setIsLoading(true);
        var data: any = {
          name: formValues?.name,
          payments: formValues.payments,
          authorization: formValues.authorization,
          subscription: formValues.subscription,
          payout: formValues.payout,
          refund: formValues.refund,
          apm: formValues.apm,
          direct_debit: formValues.direct_debit,
          payin: formValues?.payin,
          token: formValues?.token,
          is_active: formValues?.is_active,
          timezone: formValues?.timezone,
          card_types: checkedCards?.map((item: any) => item?.value),
          id: formValues?.id,
          meta_info: {
            business_name: formValues?.business_name,
            subscription_key: formValues?.subscription_key,
            base_url: formValues?.base_url,
            authorization: formValues?.authorization_name,
            password: formValues?.password,
            username: formValues?.username,
            statement_descriptor: formValues?.statement_descriptor,
            statement_descriptor_suffix:
              formValues?.statement_descriptor_suffix,
            secret_key: formValues?.secret_key,
            app_id: formValues?.app_id,
            body: formValues?.body,
            key: formValues?.key,
            merchant_id: formValues?.merchant_id,
            nonce_string: formValues?.nonce_string,
            notify_url: formValues?.notify_url,
            tkr_test: formValues?.tkr_test,
            commerce_name_test: formValues?.commerce_name_test,
            commerce_id_test: formValues?.commerce_id_test,
            id_branch_test: formValues?.id_branch_test,
            id_company_test: formValues?.id_company_test,
            merchant_id_test: formValues?.merchant_id_test,
            password_test: formValues?.password_test,
            user_code_test: formValues?.user_code_test,
            secret_key_test: formValues?.secret_key_test,
            access_key_test: formValues?.access_key_test,
            username_test: formValues?.username_test,
            apikey_test: formValues?.apikey_test,
            secretKey_test: formValues?.secretKey_test,
            brand_id_test: formValues?.brand_id_test,
            merchantId_test: formValues?.merchantId_test,
            passcode_test: formValues?.passcode_test,
            shop_id_test: formValues?.shop_id_test,
            shop_secret_key_test: formValues?.shop_secret_key_test,
            companyNum_test: formValues?.companyNum_test,
            personalHashkey_test: formValues?.personalHashkey_test,
            hash_key_test: formValues?.hash_key_test,
            api_token_test: formValues?.api_token_test,
            terminal_id_test: formValues?.terminal_id_test,
            api_key_test: formValues?.api_key_test,
            api_secret_test: formValues?.api_secret_test,
            merchant_domain_test: formValues?.merchant_domain_test,
            client_id_test: formValues?.client_id_test,
            client_secret_test: formValues?.client_secret_test,
            name_test: formValues?.name_test,
            email_test: formValues?.email_test,
            marechant_id: formValues?.marechant_id,
            merchant_private_key_test: formValues?.merchant_private_key_test,
            merchant_key_test: formValues?.merchant_key_test,
            platy_api_key_test : formValues?.platy_api_key_test,
            platy_secret_key_test : formValues.platy_secret_key_test,
            recurring_passcode_test : formValues.recurring_passcode_test,
            reporting_passcode_test : formValues.reporting_passcode_test,
            batch_passcode_test : formValues.batch_passcode_test,
            subscription_passcode_test : formValues.subscription_passcode_test,
            // password_test: formValues?.merchant_private_key_test,
            // term_url_3ds_test: formValues?.term_url_3ds_test || "",
          },
          test_endpoint: formValues.test_endpoint,
          live_endpoint: formValues.live_endpoint,

          is_payment_cronjob_enabled:
            formValues?.is_payment_cronjob_enabled ?? false,
          payment_cronjob_sleep_time: Number(
            formValues?.payment_cronjob_sleep_time
          ),
          is_payout_cronjob_enabled:
            formValues?.is_payout_cronjob_enabled ?? false,
          payout_cronjob_sleep_time: Number(
            formValues?.payout_cronjob_sleep_time
          ),

          transaction_status_enabled_by : formValues?.transaction_status_enabled_by ?? 2
        };
        const data1: any = {
          is_active: formValues?.is_active,
          id: formValues?.id,
        };
        await updateGateway(formValues?.is_active ? data : data1);
        toast.success("Gateway Details Updated Successfully.");
        fetchUsers();
        handleClose();
      } catch (err: any) {
        if (err?.response?.status === 422) {
          toast.error("Please provide valid values");
        } else {
          toast.error(err?.response?.data?.message ?? "Unable save user data");
        }
      } finally {
        setIsLoading(false);
      }
    }
  }
  return (
    <>
      <div
        onClick={() => {
          if (data) {
            initForm();
          }
          onOpen();
        }}
      >
        {is_info ? (
          <MdInfo
            className="h-5 w-5 cursor-pointer text-blue-500"
            title="View"
          />
        ) : id ? (
          roleData?.[0]?.payment_method?.value?.edit_payment_method && (
            <MdEdit
              className="h-5 w-5 cursor-pointer text-blue-500"
              title="Edit"
            />
          )
        ) : null}
      </div>
      <Modal isOpen={isOpen} onClose={() => { }}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="sm:max-w-xxl scrollbarhide   z-[1002] !m-auto flex max-h-[100vh] min-h-[85vh] max-w-[800px]   justify-start overflow-auto sm:my-8 sm:w-full ">
          <ModalBody className="my-0 p-0 py-0">
            <Card
              style={{
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderTopRightRadius: "15px",
                borderTopLeftRadius: "10px",
              }}
              extra=" max-w-[800px] sm:w-full flex flex-col justify-start !z-[1004] max-h-[75vh] min-h-[70vh] sm:max-h-[100vh] sm:min-h-[85vh] scrollbarhide overflow-auto"
            >
              <h1 className="p-5 px-[30px] text-2xl font-bold">
                {is_info
                  ? "View"
                  : id
                    ? "Update Gateway Info "
                    : "Create Gateway"}
              </h1>
              {is_info && (
                <MdEdit
                  className="absolute right-7 top-10 h-6 w-6 cursor-pointer text-indigo-500"
                  onClick={() => {
                    setViewMode(!viewMode);
                  }}
                />
              )}
              <div className="px-[35px]">
                <div className="w-full">
                  <div className="mt-5 flex gap-8">
                    <div className="flex gap-2">
                      <label
                        className={`ml-1.5 mr-3 text-base font-bold text-navy-700 dark:text-white`}
                      >
                        Id : {formValues?.id}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="w-full">
                  <div className="mt-5 flex gap-8">
                    <div className="flex gap-2">
                      <label
                        className={`ml-1.5 mr-3 text-sm font-bold text-gray-900 text-navy-700 dark:text-white `}
                      >
                        Status
                      </label>
                    </div>
                  </div>

                  <div className="mb-6 flex items-center justify-center">
                    <select
                      id="is_active"
                      value={formValues?.is_active}
                      onChange={handleValueChange}
                      className="mt-1 flex h-12 w-full items-center justify-center rounded-xl border  bg-white/0 p-3 text-sm text-gray-700 outline-none dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                    >
                      <option value="true">Active</option>
                      <option value="false">Inactive</option>
                    </select>
                  </div>
                </div>
                <div className="w-full">
                  <div className="mt-5 flex gap-8">
                    <div className="flex gap-2">
                      <label
                        className={`ml-1.5 mr-3 text-sm font-bold text-gray-900 text-navy-700 dark:text-white`}
                      >
                        Timezone
                      </label>
                    </div>
                  </div>

                  <div className="mb-6 flex items-center justify-center">
                    <select
                      id="timezone"
                      name="timezone"
                      value={formValues?.timezone}
                      onChange={handleValueChange}
                      className="mt-1 flex h-10 w-full items-center justify-center rounded-xl border  bg-white/0 p-2 text-sm text-gray-700 outline-none dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                    >
                      <option value="">Select TimeZone</option>
                      {timeZone?.map((data: any) => {
                        return (
                          <option
                            value={data?.label}
                          >{`${data?.label}(${data?.value})`}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="flex h-full w-full items-center gap-4 overflow-x-auto">
                  <div className=" flex items-center justify-between p-2">
                    <div className="">
                      <p className="mb-1 text-sm font-medium text-navy-700 dark:text-white">
                        Payment
                      </p>
                      <div className="flex justify-center">
                        <Checkbox
                          id="payments"
                          value={!formValues?.payments}
                          disabled={
                            formValues?.is_active === false
                              ? true
                              : false || formValues?.payments == null
                          }
                          className={`${formValues?.payments == null
                            ? "h-5 w-5 dark:bg-gray-700"
                            : "h-5 w-5"
                            }`}
                          color={
                            formValues?.is_active === false ||
                              formValues?.payments == null
                              ? "gray"
                              : ""
                          }
                          checked={formValues?.payments}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" flex items-center justify-between p-2">
                    <div className="">
                      <p className="mb-1 text-sm font-medium text-navy-700 dark:text-white  ">
                        Refund
                      </p>
                      <div className="flex justify-center">
                        <Checkbox
                          id="refund"
                          value={!formValues?.refund}
                          disabled={
                            formValues?.is_active === false
                              ? true
                              : false || formValues?.refund == null
                          }
                          className={`${formValues?.refund == null
                            ? "h-5 w-5 dark:bg-gray-700"
                            : "h-5 w-5"
                            }`}
                          color={
                            formValues?.is_active === false ||
                              formValues?.refund == null
                              ? "gray"
                              : ""
                          }
                          checked={formValues?.refund}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" flex items-center justify-between p-2">
                    <div className="">
                      <p className="mb-1 text-sm font-medium text-navy-700 dark:text-white  ">
                        APM
                      </p>
                      <div className="flex justify-center">
                        <Checkbox
                          id="apm"
                          value={!formValues?.apm}
                          disabled={
                            formValues?.is_active === false
                              ? true
                              : false || formValues?.apm == null
                          }
                          className={`${formValues?.apm == null
                            ? "h-5 w-5 dark:bg-gray-700"
                            : "h-5 w-5"
                            }`}
                          color={
                            formValues?.is_active === false ||
                              formValues?.apm == null
                              ? "gray"
                              : ""
                          }
                          checked={formValues?.apm}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" flex items-center justify-between p-2">
                    <div className="">
                      <p className="mb-1 text-sm font-medium text-navy-700 dark:text-white">
                        Authorization
                      </p>
                      <div className="flex justify-center">
                        <Checkbox
                          id="authorization"
                          value={!formValues?.authorization}
                          disabled={
                            formValues?.is_active === false
                              ? true
                              : false || formValues?.authorization == null
                          }
                          className={`${formValues?.authorization == null
                            ? "h-5 w-5 dark:bg-gray-700"
                            : "h-5 w-5"
                            }`}
                          color={
                            formValues?.is_active === false ||
                              formValues?.authorization == null
                              ? "gray"
                              : ""
                          }
                          checked={formValues?.authorization}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" flex items-center justify-between p-2">
                    <div className="">
                      <p className="mb-1 text-sm font-medium text-navy-700 dark:text-white">
                        Subscription
                      </p>
                      <div className="flex justify-center">
                        <Checkbox
                          id="subscription"
                          value={!formValues?.subscription}
                          disabled={
                            formValues?.is_active === false
                              ? true
                              : false || formValues?.subscription == null
                          }
                          className={`${formValues?.subscription == null
                            ? "h-5 w-5 dark:bg-gray-700"
                            : "h-5 w-5"
                            }`}
                          color={
                            formValues?.is_active === false ||
                              formValues?.subscription == null
                              ? "gray"
                              : ""
                          }
                          checked={formValues?.subscription}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" flex items-center justify-between p-2">
                    <div className="">
                      <p className="mb-1 text-sm font-medium text-navy-700 dark:text-white  ">
                        Token
                      </p>
                      <div className="flex justify-center">
                        <Checkbox
                          id="token"
                          value={!formValues?.token}
                          disabled={
                            formValues?.is_active === false
                              ? true
                              : false || formValues?.token == null
                          }
                          className={`${formValues?.token == null
                            ? "h-5 w-5 dark:bg-gray-700"
                            : "h-5 w-5"
                            }`}
                          color={
                            formValues?.is_active === false ||
                              formValues?.token == null
                              ? "gray"
                              : ""
                          }
                          checked={formValues?.token}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" flex items-center justify-between p-2">
                    <div className="">
                      <p className="mb-1 text-sm font-medium text-navy-700 dark:text-white  ">
                        Payout
                      </p>
                      <div className="flex justify-center">
                        <Checkbox
                          id="payout"
                          value={!formValues?.payout}
                          disabled={
                            formValues?.is_active === false
                              ? true
                              : false || formValues?.payout == null
                          }
                          className={`${formValues?.payout == null
                            ? "h-5 w-5 dark:bg-gray-700"
                            : "h-5 w-5"
                            }`}
                          color={
                            formValues?.is_active === false ||
                              formValues?.payout == null
                              ? "gray"
                              : ""
                          }
                          checked={formValues?.payout}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" flex items-center justify-between p-2">
                    <div className="">
                      <p className="mb-1 text-sm font-medium text-navy-700 dark:text-white  ">
                        Payin
                      </p>
                      <div className="flex justify-center">
                        <Checkbox
                          id="payin"
                          value={!formValues?.payin}
                          disabled={
                            formValues?.is_active === false
                              ? true
                              : false || formValues?.payin == null
                          }
                          className={`${formValues?.payin == null
                            ? "h-5 w-5 dark:bg-gray-700"
                            : "h-5 w-5"
                            }`}
                          color={
                            formValues?.is_active === false ||
                              formValues?.payin == null
                              ? "gray"
                              : ""
                          }
                          checked={formValues?.payin}
                          onChange={handleValueChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="h-4 w-full">
                  <div className="mt-5 flex gap-8">
                    <label
                      className={`ml-1.5 mr-3 text-2xl font-bold text-navy-700 dark:text-white`}
                    >
                      Card types
                    </label>
                  </div>
                </div>
                <div className=" mt-5 flex items-center justify-between p-2">
                  <div
                    className="mb-2 mt-2 text-sm text-navy-700 dark:text-white"
                    style={{ minWidth: "100%" }}
                  >
                    <MultiSelect
                      options={allCardType?.map((item: any) => {
                        return {
                          label: item,
                          value: item,
                        };
                      })}
                      value={checkedCards}
                      onChange={handleCardChange}
                      labelledBy={"Select"}
                      isCreatable={true}
                      disabled={viewMode}
                    />
                  </div>
                </div>
                <div className="h-4 w-full">
                  <div className="mt-5 flex gap-8">
                    <label
                      className={`ml-1.5 mr-3 text-2xl font-bold text-navy-700 dark:text-white`}
                    >
                      Test Credentials
                    </label>
                  </div>
                </div>
                {formValues?.name && (
                  <div className="mt-5 max-h-[calc(100vh-300px)] overflow-auto overflow-x-hidden rounded-xl border border-gray-200 bg-white p-5 shadow-3xl shadow-shadow-500 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 dark:!border-white/10 dark:bg-navy-800 dark:shadow-none">
                    {formValues?.name === "Sipe" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Merchant ID*"
                          placeholder="Google inc."
                          id="merchant_id"
                          type="text"
                          value={formValues?.merchant_id ?? ""}
                          state={formValuesErr?.merchant_id ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="App ID*"
                          placeholder="Google inc."
                          id="app_id"
                          type="text"
                          value={formValues?.app_id ?? ""}
                          state={formValuesErr?.app_id ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Nonce Str*"
                          placeholder="Google inc."
                          id="nonce_string"
                          type="text"
                          value={formValues?.nonce_string ?? ""}
                          state={formValuesErr?.nonce_string ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Notify URL*"
                          placeholder=""
                          id="notify_url"
                          type="text"
                          value={formValues?.notify_url ?? ""}
                          state={formValuesErr?.notify_url ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Blumon" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Business Name"
                          placeholder="Google inc."
                          id="business_name"
                          type="text"
                          value={formValues?.business_name ?? ""}
                          state={formValuesErr?.business_name ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Username*"
                          placeholder="Username"
                          id="username"
                          type="text"
                          value={formValues?.username ?? ""}
                          state={formValuesErr?.username ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Password*"
                          placeholder="********"
                          id="password"
                          type="password"
                          value={formValues?.password ?? ""}
                          state={formValuesErr?.password ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Stripe" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Secret Key*"
                          placeholder="Secret key"
                          id="secret_key"
                          type="text"
                          value={formValues?.secret_key ?? ""}
                          state={formValuesErr?.secret_key ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <div className="mt-3 flex w-full gap-3">
                          <InputField
                            disabled={
                              formValues?.is_active === false ? true : false
                            }
                            variant="auth"
                            extra="mb-1 w-full"
                            label="Statement Descriptor"
                            placeholder="Custom descriptor"
                            id="statement_descriptor"
                            type="text"
                            value={formValues?.statement_descriptor ?? ""}
                            state={
                              formValuesErr?.statement_descriptor ? "error" : ""
                            }
                            onChange={handleValueChange}
                          />
                          <InputField
                            disabled={
                              formValues?.is_active === false ? true : false
                            }
                            variant="auth"
                            extra="mb-1 w-full"
                            label="Statement Descriptor Suffix"
                            placeholder="Custom descriptor suffix"
                            id="statement_descriptor_suffix"
                            type="text"
                            value={
                              formValues?.statement_descriptor_suffix ?? ""
                            }
                            state={
                              formValuesErr?.statement_descriptor_suffix
                                ? "error"
                                : ""
                            }
                            onChange={handleValueChange}
                          />
                        </div>
                      </>
                    )}

                    {formValues?.name === "Pix-Lotus" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Subscription key*"
                          placeholder=""
                          id="subscription_key"
                          type="text"
                          value={formValues?.subscription_key ?? ""}
                          state={formValuesErr?.subscription_key ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1 w-full"
                          label="Authorization"
                          placeholder=""
                          id="authorization_name"
                          type="text"
                          value={formValues?.authorization_name ?? ""}
                          state={
                            formValuesErr?.authorization_name ? "error" : ""
                          }
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1 w-full"
                          label="Base URL"
                          placeholder=""
                          id="base_url"
                          type="text"
                          value={formValues?.base_url ?? ""}
                          state={formValuesErr?.base_url ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Pix" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Email*"
                          placeholder="Email"
                          id="email_test"
                          type="text"
                          value={formValues.email_test ?? ""}
                          state={formValuesErr?.email_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Password"
                          placeholder="Password"
                          id="name_test"
                          type="text"
                          value={formValues?.name_test ?? ""}
                          state={formValuesErr?.name_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Memphis" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Commerce Id*"
                          placeholder="Commerce Id"
                          id="commerce_id_test"
                          type="text"
                          value={formValues?.commerce_id_test ?? ""}
                          state={formValuesErr?.commerce_id_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Commerce Name*"
                          placeholder="Commerce Name"
                          id="commerce_name_test"
                          type="text"
                          value={formValues?.commerce_name_test ?? ""}
                          state={
                            formValuesErr?.commerce_name_test ? "error" : ""
                          }
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="TKR*"
                          placeholder="TKR"
                          id="tkr_test"
                          type="text"
                          value={formValues?.tkr_test ?? ""}
                          state={formValuesErr?.tkr_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Raypd" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Secret Key*"
                          placeholder="Secret key"
                          id="secret_key_test"
                          type="text"
                          value={formValues?.secret_key_test ?? ""}
                          state={formValuesErr?.secret_key_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Access Key*"
                          placeholder="Access key"
                          id="access_key_test"
                          type="text"
                          value={formValues?.access_key_test ?? ""}
                          state={formValuesErr?.access_key_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Banwire" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Username*"
                          placeholder="Username"
                          id="username_test"
                          type="text"
                          value={formValues?.username_test ?? ""}
                          state={formValuesErr?.username_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Valitor" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="API Key*"
                          placeholder="API Key"
                          id="apikey_test"
                          type="text"
                          value={formValues?.apikey_test ?? ""}
                          state={formValuesErr?.apikey_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Bambora" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Merchant Id*"
                          placeholder="Merchant Id"
                          id="merchantId_test"
                          type="text"
                          value={formValues?.merchantId_test ?? ""}
                          state={formValuesErr?.merchantId_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Payment Passcode*"
                          placeholder="Payment Passcode"
                          id="passcode_test"
                          type="text"
                          value={formValues?.passcode_test ?? ""}
                          state={formValuesErr?.passcode_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Hash Key*"
                          placeholder="Hash key"
                          id="hash_key_test"
                          type="text"
                          value={formValues?.hash_key_test ?? ""}
                          state={formValuesErr?.hash_key_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Recurring Passcode*"
                          placeholder="Recurring Passcode"
                          id="recurring_passcode_test"
                          type="text"
                          value={formValues?.recurring_passcode_test ?? ""}
                          state={formValuesErr?.recurring_passcode_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Reporting Passcode*"
                          placeholder="Reporting Passcode"
                          id="reporting_passcode_test"
                          type="text"
                          value={formValues?.reporting_passcode_test ?? ""}
                          state={formValuesErr?.reporting_passcode_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Batch Passcode*"
                          placeholder="Batch Passcode"
                          id="batch_passcode_test"
                          type="text"
                          value={formValues?.batch_passcode_test ?? ""}
                          state={formValuesErr?.batch_passcode_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Subscription Passcode*"
                          placeholder="Subscription Passcode"
                          id="subscription_passcode_test"
                          type="text"
                          value={formValues?.subscription_passcode_test ?? ""}
                          state={formValuesErr?.subscription_passcode_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Re-set" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Secret Key*"
                          placeholder="Secret Key"
                          id="secretKey_test"
                          type="text"
                          value={formValues?.secretKey_test ?? ""}
                          state={formValuesErr?.secretKey_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Brand ID*"
                          placeholder="Brand ID"
                          id="brand_id_test"
                          type="text"
                          value={formValues?.brand_id_test ?? ""}
                          state={formValuesErr?.brand_id_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "MIT" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Id Branch*"
                          placeholder="Id Branch"
                          id="id_branch_test"
                          type="text"
                          value={formValues?.id_branch_test ?? ""}
                          state={formValuesErr?.id_branch_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Id Company*"
                          placeholder="Id Company"
                          id="id_company_test"
                          type="text"
                          value={formValues?.id_company_test ?? ""}
                          state={formValuesErr?.id_company_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Merchant ID*"
                          placeholder="Google inc."
                          id="merchant_id_test"
                          type="text"
                          value={formValues?.merchant_id_test ?? ""}
                          state={formValuesErr?.merchant_id_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Password*"
                          placeholder="********"
                          id="password_test"
                          type="password"
                          value={formValues?.password_test ?? ""}
                          state={formValuesErr?.password_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="User Code*"
                          placeholder="User Code"
                          id="user_code_test"
                          type="text"
                          value={formValues?.user_code_test ?? ""}
                          state={formValuesErr?.user_code_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Scipiopay" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Shop Id*"
                          placeholder="Shop Id"
                          id="shop_id_test"
                          type="text"
                          value={formValues?.shop_id_test ?? ""}
                          state={formValuesErr?.shop_id_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Shop Secret Key*"
                          placeholder="Shop secret key"
                          id="shop_secret_key_test"
                          type="text"
                          value={formValues?.shop_secret_key_test ?? ""}
                          state={
                            formValuesErr?.shop_secret_key_test ? "error" : ""
                          }
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Aurea Via" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Company Numbers*"
                          placeholder="Company number"
                          id="companyNum_test"
                          type="text"
                          value={formValues?.companyNum_test ?? ""}
                          state={formValuesErr?.companyNum_test ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Personal Hash key*"
                          placeholder="Personal hash key"
                          id="personalHashkey_test"
                          type="text"
                          value={formValues?.personalHashkey_test ?? ""}
                          state={
                            formValuesErr?.personalHashkey_test ? "error" : ""
                          }
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Betapay" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Api Token*"
                          placeholder="Api token"
                          id="api_token_test"
                          type="text"
                          value={formValues?.api_token_test ?? ""}
                          state={formValuesErr?.api_token_test ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Merchant Id*"
                          placeholder="Merchant id "
                          id="merchant_id_test"
                          type="text"
                          value={formValues?.merchant_id_test ?? ""}
                          state={formValuesErr?.merchant_id_test ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Terminal Id*"
                          placeholder="Terminal id"
                          id="terminal_id_test"
                          type="text"
                          value={formValues?.terminal_id_test ?? ""}
                          state={formValuesErr?.terminal_id_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Kasha" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Api Key*"
                          placeholder="Api Key"
                          id="api_key_test"
                          type="text"
                          value={formValues?.api_key_test ?? ""}
                          state={formValuesErr?.api_key_test ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Merchant Domain*"
                          placeholder="Merchant domain"
                          id="merchant_domain_test"
                          type="text"
                          value={formValues?.merchant_domain_test ?? ""}
                          state={
                            formValuesErr?.merchant_domain_test ? "error" : ""
                          }
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Linx" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Client Id*"
                          placeholder="Client id"
                          id="client_id_test"
                          type="text"
                          value={formValues?.client_id_test ?? ""}
                          state={formValuesErr?.client_id_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Client Secret*"
                          placeholder="Client Secret"
                          id="client_secret_test"
                          type="text"
                          value={formValues?.client_secret_test ?? ""}
                          state={
                            formValuesErr?.client_secret_test ? "error" : ""
                          }
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Supefina" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Marechant Id*"
                          placeholder="Marechant Id"
                          id="marechant_id"
                          type="text"
                          value={formValues?.marechant_id ?? ""}
                          state={formValuesErr?.marechant_id ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Platy" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Api Key*"
                          placeholder="Api Key"
                          id="api_key_test"
                          type="text"
                          value={formValues?.api_key_test ?? ""}
                          state={formValuesErr?.api_key_test ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Username*"
                          placeholder="Username"
                          id="username_test"
                          type="text"
                          value={formValues?.username_test ?? ""}
                          state={formValuesErr?.username_test ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Password*"
                          placeholder="Password"
                          id="password_test"
                          type="text"
                          value={formValues?.password_test ?? ""}
                          state={formValuesErr?.password_test ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Platy Api Key*"
                          placeholder="Platy Api Key"
                          id="platy_api_key_test"
                          type="text"
                          value={formValues?.platy_api_key_test ?? ""}
                          state={
                            formValuesErr?.platy_api_key_test ? "error" : ""
                          }
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Platy Api Secret Key*"
                          placeholder="Platy Api Secret Key"
                          id="platy_secret_key_test"
                          type="text"
                          value={formValues?.platy_secret_key_test ?? ""}
                          state={
                            formValuesErr?.platy_secret_key_test ? "error" : ""
                          }
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "3xpay" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Api Key*"
                          placeholder="Api Key"
                          id="api_key_test"
                          type="text"
                          value={formValues?.api_key_test ?? ""}
                          state={formValuesErr?.api_key_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Api Secret*"
                          placeholder="Api Secret"
                          id="api_secret_test"
                          type="text"
                          value={formValues?.api_secret_test ?? ""}
                          state={formValuesErr?.api_secret_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Platy 2DS" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Api Key*"
                          placeholder="Api Key"
                          id="api_key_test"
                          type="text"
                          value={formValues?.api_key_test ?? ""}
                          state={formValuesErr?.api_key_test ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Username*"
                          placeholder="Username"
                          id="username_test"
                          type="text"
                          value={formValues?.username_test ?? ""}
                          state={formValuesErr?.username_test ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Password*"
                          placeholder="Password"
                          id="password_test"
                          type="text"
                          value={formValues?.password_test ?? ""}
                          state={formValuesErr?.password_test ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Platy Api Key*"
                          placeholder="Platy Api Key"
                          id="platy_api_key_test"
                          type="text"
                          value={formValues?.platy_api_key_test ?? ""}
                          state={
                            formValuesErr?.platy_api_key_test ? "error" : ""
                          }
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Platy Api Secret Key*"
                          placeholder="Platy Api Secret Key"
                          id="platy_secret_key_test"
                          type="text"
                          value={formValues?.platy_secret_key_test ?? ""}
                          state={
                            formValuesErr?.platy_secret_key_test ? "error" : ""
                          }
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Platy 3DS" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Api Key*"
                          placeholder="Api Key"
                          id="api_key_test"
                          type="text"
                          value={formValues?.api_key_test ?? ""}
                          state={formValuesErr?.api_key_test ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Username*"
                          placeholder="Username"
                          id="username_test"
                          type="text"
                          value={formValues?.username_test ?? ""}
                          state={formValuesErr?.username_test ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Password*"
                          placeholder="Password"
                          id="password_test"
                          type="text"
                          value={formValues?.password_test ?? ""}
                          state={formValuesErr?.password_test ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Platy Api Key*"
                          placeholder="Platy Api Key"
                          id="platy_api_key_test"
                          type="text"
                          value={formValues?.platy_api_key_test ?? ""}
                          state={
                            formValuesErr?.platy_api_key_test ? "error" : ""
                          }
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Platy Api Secret Key*"
                          placeholder="Platy Api Secret Key"
                          id="platy_secret_key_test"
                          type="text"
                          value={formValues?.platy_secret_key_test ?? ""}
                          state={
                            formValuesErr?.platy_secret_key_test ? "error" : ""
                          }
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Masterpay" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Merchant Private Key*"
                          placeholder="Merchant Private Key"
                          id="merchant_private_key_test"
                          type="text"
                          value={formValues.merchant_private_key_test ?? ""}
                          state={
                            formValuesErr?.merchant_private_key_test
                              ? "error"
                              : ""
                          }
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Neonpay" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Merchant Key*"
                          placeholder="Merchant Key"
                          id="merchant_key_test"
                          type="text"
                          value={formValues.merchant_key_test ?? ""}
                          state={
                            formValuesErr?.merchant_key_test ? "error" : ""
                          }
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Password*"
                          placeholder="Password"
                          id="password_test"
                          type="text"
                          value={formValues.password_test ?? ""}
                          state={formValuesErr?.password_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        {/* <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Term Url 3ds*"
                          placeholder="Term Url 3ds"
                          id="term_url_3ds_test"
                          type="text"
                          value={formValues.term_url_3ds_test ?? ""}
                          state={
                            formValuesErr?.term_url_3ds_test ? "error" : ""
                          }
                          onChange={handleValueChange}
                        /> */}
                      </>
                    )}

                    {formValues?.name === "Bm2pay" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="test_endpoint"
                          type="text"
                          value={formValues.test_endpoint ?? ""}
                          state={formValuesErr?.test_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />

                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Client Id*"
                          placeholder="Client id"
                          id="client_id_test"
                          type="text"
                          value={formValues?.client_id_test ?? ""}
                          state={formValuesErr?.client_id_test ? "error" : ""}
                          onChange={handleValueChange}
                        />
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Client Secret*"
                          placeholder="Client Secret"
                          id="client_secret_test"
                          type="text"
                          value={formValues?.client_secret_test ?? ""}
                          state={
                            formValuesErr?.client_secret_test ? "error" : ""
                          }
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                  </div>
                )}

                <div className="h-4 w-full">
                  <div className="mt-5 flex gap-8">
                    <label
                      className={`ml-1.5 mr-3 text-2xl font-bold text-navy-700 dark:text-white`}
                    >
                      Live Credentials
                    </label>
                  </div>
                </div>

                {formValues?.name && (
                  <div className="mt-5 max-h-[calc(100vh-300px)] overflow-auto overflow-x-hidden rounded-xl border border-gray-200 bg-white p-5 shadow-3xl shadow-shadow-500 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 dark:!border-white/10 dark:bg-navy-800 dark:shadow-none">
                    {formValues?.name === "Sipe" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Blumon" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Stripe" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Pix-Lotus" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Pix" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Memphis" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Raypd" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}

                    {formValues?.name === "Banwire" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "Valitor" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "Bambora" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "Re-set" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "MIT" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "Scipiopay" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "Aurea Via" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "Betapay" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "Kasha" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "Linx" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "Supefina" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "Platy" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "3xpay" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "Platy 2DS" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "Platy 3DS" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "Masterpay" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                    {formValues?.name === "Neonpay" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                      {formValues?.name === "Bm2pay" && (
                      <>
                        <InputField
                          disabled={
                            formValues?.is_active === false ? true : false
                          }
                          variant="auth"
                          extra="mb-1"
                          label="Endpoint*"
                          placeholder="https://gateway.com"
                          id="live_endpoint"
                          type="text"
                          value={formValues.live_endpoint ?? ""}
                          state={formValuesErr?.live_endpoint ? "error" : ""}
                          onChange={handleValueChange}
                        />
                      </>
                    )}
                  </div>
                )}
                {(formValues?.payments || formValues?.payout) && (
                  <div className="mb-3 h-4 w-full">
                    <div className="mt-5 flex gap-8">
                      <label
                        className={`ml-1.5 mr-3 text-2xl font-bold text-navy-700 dark:text-white`}
                      >
                        Cron Job Settings
                      </label>
                    </div> 
                    <div className="mt-2 max-h-[calc(100vh-300px)] overflow-auto overflow-x-hidden rounded-xl border border-gray-200 bg-white p-5 shadow-3xl shadow-shadow-500 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 dark:!border-white/10 dark:bg-navy-800 dark:shadow-none">
                    <p className="mb-1 flex text-sm font-medium text-navy-700 dark:text-white">
                    <b>
                      <span className="mr-5">Webhook</span>
                    </b>
                    <div>
                      <label className="relative mb-3 inline-flex cursor-pointer items-center">
                        <input
                          type="checkbox"
                          className="peer sr-only"
                          checked={formValues?.transaction_status_enabled_by === 1}
                          id="transaction_status_enabled_by_webhook"
                          onChange={() => {
                            setFormValues({
                              ...formValues,
                              transaction_status_enabled_by: formValues.transaction_status_enabled_by === 1 ? 2 : 1,
                              is_payment_cronjob_enabled: formValues.transaction_status_enabled_by === 1 ? true : false
                            });
                          }}
                        />
                        <div className="peer h-6 w-11 rounded-full bg-gray-400 after:absolute after:left-[2px] after:top-[4px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-400 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-indigo-800" />
                        <span className="text-lg font-medium text-gray-900 dark:text-gray-300" style={{ visibility: "hidden" }}>
                          {formValues?.transaction_status_enabled_by === 1 ? "A" : "I"}
                        </span>
                      </label>
                    </div>
                  </p>
                  <p className="mb-1 flex text-sm font-medium text-navy-700 dark:text-white">
                    <b>
                      <span className="mr-5">Cronjob</span>
                    </b>
                    <div>
                      <label className="relative mb-3 inline-flex cursor-pointer items-center">
                        <input
                          type="checkbox"
                          className="peer sr-only"
                          checked={formValues?.transaction_status_enabled_by === 2}
                          id="transaction_status_enabled_by_cronjob"
                          onChange={() => {
                            setFormValues({
                              ...formValues,
                              transaction_status_enabled_by: formValues.transaction_status_enabled_by === 2 ? 1 : 2,
                              is_payment_cronjob_enabled: formValues.transaction_status_enabled_by === 2 ? false  : true
                            });
                          }}
                        />
                        <div className="peer h-6 w-11 rounded-full bg-gray-400 after:absolute after:left-[2px] after:top-[4px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-400 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-indigo-800" />
                        <span className="text-lg font-medium text-gray-900 dark:text-gray-300" style={{ visibility: "hidden" }}>
                          {formValues?.transaction_status_enabled_by === 2 ? "A" : "I"}
                        </span>
                      </label>
                    </div>
                  </p>


                      {formValues?.transaction_status_enabled_by == 1 ?
                        <div className="mt-2 max-h-[calc(100vh-300px)] overflow-auto overflow-x-hidden rounded-xl border border-gray-200 bg-white p-5 shadow-3xl shadow-shadow-500 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 dark:!border-white/10 dark:bg-navy-800 dark:shadow-none">
                          <InputField
                            variant="auth"
                            extra="mb-1"
                            label="Webhook URL"
                            placeholder="Enter webhook url"
                            id="webhook_url"
                            type="text"
                            disabled
                            value={
                              formValues?.webhook_url ?? ""
                            }

                          />
                          <p>
                            <span className="mr-5 opacity-50">*Transaction status is managed by gateway webhook.</span>

                          </p>

                        </div>
                      : 
                        <div>
                          {formValues?.payments && (
                            <div className="mt-2 max-h-[calc(100vh-300px)] overflow-auto overflow-x-hidden rounded-xl border border-gray-200 bg-white p-5 shadow-3xl shadow-shadow-500 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 dark:!border-white/10 dark:bg-navy-800 dark:shadow-none">
                              <div className="">
                                {/* <p className="mb-1 flex text-sm font-medium text-navy-700 dark:text-white  ">
                                  <b>
                                    <span className="mr-5">Payment</span>
                                  </b>
                                  <div className="">
                                    <label className="relative mb-3 inline-flex cursor-pointer items-center">
                                      <input
                                        type="checkbox"
                                        className="peer sr-only"
                                        // @ts-ignore
                                        checked={
                                          formValues?.is_payment_cronjob_enabled
                                        }
                                        id={"is_payment_cronjob_enabled"}
                                        onChange={() => {
                                          setFormValues({
                                            ...formValues,
                                            is_payment_cronjob_enabled:
                                              !formValues.is_payment_cronjob_enabled,
                                          });
                                        }}
                                      />
                                      <div className="peer h-6 w-11 rounded-full bg-gray-400 after:absolute after:left-[2px] after:top-[4px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-400 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-indigo-800" />
                                      <span
                                        className=" text-lg font-medium text-gray-900 dark:text-gray-300"
                                        style={{ visibility: "hidden" }}
                                      >
                                        {formValues?.is_payout_cronjob_enabled
                                          ? "A"
                                          : "I"}
                                      </span>
                                    </label>
                                  </div>
                                </p> */}
                                <div className="text-start">
                                  <InputField
                                    variant="auth"
                                    extra="mb-1"
                                    label="Time(Seconds)*"
                                    placeholder="Enter number"
                                    id="payment_cronjob_sleep_time"
                                    type="number"
                                    value={
                                      formValues?.payment_cronjob_sleep_time ?? ""
                                    }
                                    state={
                                      formValues?.is_payment_cronjob_enabled
                                        ? formValuesErr?.payment_cronjob_sleep_time
                                          ? "error"
                                          : ""
                                        : ""
                                    }
                                    onChange={handleValueChange}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {formValues?.payout && (
                            <div className="mt-2 max-h-[calc(100vh-300px)] overflow-auto overflow-x-hidden rounded-xl border border-gray-200 bg-white p-5 shadow-3xl shadow-shadow-500 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 dark:!border-white/10 dark:bg-navy-800 dark:shadow-none">
                              <div className="">
                                <p className="mb-1 flex text-sm font-medium text-navy-700 dark:text-white  ">
                                  <b>
                                    <span className="mr-5">Payout</span>
                                  </b>
                                  <div className="">
                                    <label className="relative mb-3 inline-flex cursor-pointer items-center">
                                      <input
                                        type="checkbox"
                                        className="peer sr-only"
                                        // @ts-ignore
                                        checked={
                                          formValues?.is_payout_cronjob_enabled
                                        }
                                        id={formValues?.type}
                                        onChange={() => {
                                          setFormValues({
                                            ...formValues,
                                            is_payout_cronjob_enabled:
                                              !formValues.is_payout_cronjob_enabled,
                                          });
                                        }}
                                      />
                                      <div className="peer h-6 w-11 rounded-full bg-gray-400 after:absolute after:left-[2px] after:top-[4px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-400 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-indigo-800" />
                                      <span
                                        className="ml-3 text-lg font-medium text-gray-900 dark:text-gray-300"
                                        style={{ visibility: "hidden" }}
                                      >
                                        {formValues?.is_payout_cronjob_enabled
                                          ? "ACTIVE"
                                          : "INACTIVE"}
                                      </span>
                                    </label>
                                  </div>
                                </p>
                                <div className="text-start">
                                  <InputField
                                    variant="auth"
                                    extra="mb-1"
                                    label="Time(Seconds)*"
                                    placeholder="Enter number"
                                    id="payout_cronjob_sleep_time"
                                    type="number"
                                    value={
                                      formValues?.payout_cronjob_sleep_time ?? ""
                                    }
                                    state={
                                      formValues?.payout_cronjob_sleep_time
                                        ? formValuesErr?.payout_cronjob_sleep_time
                                          ? "error"
                                          : ""
                                        : ""
                                    }
                                    onChange={handleValueChange}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      }
                    </div>
                    
                  </div>
                )}
              </div>
            </Card>
          </ModalBody>
          <ModalFooter
            className="m-0 bg-white p-3"
            style={{
              borderBottomRightRadius: "15px",
              borderBottomLeftRadius: "10px",
            }}
          >
            <div className="flex gap-3 bg-white pb-[10px]">
              <button
                onClick={handleClose}
                className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
              >
                Close
              </button>
              <button
                onClick={handleSubmit}
                //   disabled={viewMode}
                disabled={id ? false : true}
                className="linear rounded-xl bg-indigo-50 px-5 py-2 text-base font-medium text-indigo-500 outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
              >
                {isLoading ? (
                  <DivLoader className="h-6 w-6 border-indigo-500" />
                ) : id ? (
                  "Update"
                ) : (
                  "Create"
                )}
              </button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default CreatePaymentMethoddModal;
