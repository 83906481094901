import authGraphQLRequest from "utils/authGraphQLRequest"

export const getAllSubscription = ({ page, per_page, searchTexts }: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `
    {
        allSubscription(page: ${page}, per_page: ${per_page}, searchTexts: "${searchTexts}"){
            subscriptions
            total_count
            current_page
            total_pages
        }
    }
    `;
    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_subscriptions'
    )
      .then((responseData) => {
        resolve(responseData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const cancelSubscription = (data:any,clientId : any,clientSecret:any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `
    mutation {
      cancelSubscription(input:  { subscription_id : "${data.subscription_id}" }) {
        status
        message
      }
    }
      `;
        authGraphQLRequest<any>(
          queryString,
          '/api/v1/graphql_cancel_subscription',
          {},
          clientId,
          clientSecret
        )
          .then((responseData) => {
            resolve(responseData);
          })
          .catch((err) => {
            reject(err);
          });
  }); 
}