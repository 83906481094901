import authGraphQLRequest from "utils/authGraphQLRequest";

export const getAllTokens = ({ page, per_page, searchTexts }: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `{
    getAllTokens(page: ${page}, per_page: ${per_page}${searchTexts ? `, searchTexts: "${searchTexts}"` : ''}){
      tokens {
        id
        status
        is_active
        claimed
        created_at
        client
        gateway_id
        gateway_name
        }
      }
    }
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_all_token'
    )
      .then((responseData) => {
        console.log('promise api call graphql response user all ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err user all ::', { err });
        reject(err);
      });
  });
};
