import { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
// import PaymentMethodTable from "./components/SettlementTable";
// import { getAllUsers } from "api/users";
// import { getAllGateways } from "api/gateways";
import { getAllGateways } from "api/graphQL_gateways";
import { getTimeZone } from "api/payments";
import {  getCardType } from "api/graphQL_payments";
// import { getSingleRole } from "api/role";
import { getSingleRole } from "api/graphQL_role";
import { getId, getToken } from "utils/auth";
import { ClientContext } from "ClientProvider";
import SettlementTable from "./components/SettlementTable";
import CrerateSettlementReport from "./components/CrerateSettlementReport";
import SummaryReport from "./components/summaryReport";
// import { getOrganization } from "api/organizations";
import { getOrganization } from "api/graphQL_organizations";
// import { getClientDetails, getOrganizationDetails } from "api/dashboard";
import { getClientDetails, getOrganizationDetails } from "api/graphQL_dashboard";
import { getAllsettlement } from "api/settlement";
import { useNavigate } from "react-router-dom";
import { getAllAPIAccessToken } from "api/api_access";
export type SettlementRowObj = {
  id: string;
  name: string;
  logo: string;
  payments: boolean;
  authorization: boolean;
  subscription: boolean;
  payout: boolean;
  refund: boolean;
  apm: boolean;
  is_active: boolean;
  direct_debit?: boolean;
  payin?: boolean;
  token?: boolean;
};

const API_tab = () => {
  const [paymentData, setPaymentData] = useState<SettlementRowObj[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [page, setPage] = useState<number>(1);
  const [totalpage, setTotalPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { singleRoleData, setSingleRoleData } = useContext(ClientContext);
  const [searchTexts, setSearchTexts] = useState<string>("");
  const [timeZone, setTimeZone] = useState<any>();
  const [allCardType, setCardType] = useState<any>();
  const [allOrgDetails, setAllOrgDetails] = useState<any>();
  const navigate = useNavigate();
  useEffect(() => {
    let token = getToken();
    if (!token) {
      navigate("/auth/sign-in", { replace: true });
      window.location.reload();
    }
  }, [navigate]);
  useEffect(() => {
    let token = getToken();
    if (!token) {
      navigate("/auth/sign-in", { replace: true });
      window.location.reload();
    }
  }, []);
  const fetchAllsettlement = () => {
    setIsLoading(true);
    getAllAPIAccessToken(page, pageSize, searchTexts)
      .then((data) => {
        setPaymentData(data[0] ?? []);
        setTotalPage(data?.[1]?.total_pages);
        setTotalItems(data?.[1]?.total_items);
        setCurrentPage(data?.[1]?.current_page);
      })
      .catch((err) => {
        // toast.error(
        //   err?.response?.data?.message ??
        //     "Something went wrong while fetching organizations"
        // );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const fetchTimeZone = () => {
    getTimeZone()
      .then((data) => {
        setTimeZone(data ?? []);
      })
      .catch((err) => {
        // toast.error(
        //   err?.response?.data?.message ??
        //     "Something went wrong while fetching time zone"
        // );
      })
      .finally(() => {});
  };

  const fetchCardType = () => {
    setIsLoading(true);
    getCardType()
      .then((data) => {
        setCardType(data);
      })
      .catch((err) => {
        // toast.error(
        //   err?.response?.data?.message ??
        //     "Something went wrong while fetching CardType"
        // );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchRole = () => {
    getSingleRole(getId())
      .then((data) => {
        setSingleRoleData(data ?? []);
      })
      .catch((err) => {
        // toast.error(
        //   err?.response?.data?.message ??
        //     "Something went wrong while fetching role"
        // );
      })
      .finally(() => {});
  };

  const serchboxValueChange = async (searchTerm: string) => {
    const search_txt = searchTerm || "";

    if (search_txt !== searchTexts && searchTexts !== "") {
      await setSearchTexts(search_txt);
      setPage(1);
      setCurrentPage(1);
    } else {
      await setSearchTexts(search_txt);
    }
  };

  useEffect(() => {
    fetchAllsettlement();
  }, [searchTexts, page, pageSize]);

  const fetchSearchs = () => {
    setIsLoading(true);
  };
  const fecthOrgData = () => {
    getOrganizationDetails()
      .then((data) => {
        setAllOrgDetails(
          data?.map((d: any) => {
            return {
              ...d?.organization,
              label: d?.organization?.name,
              value: d?.organization?.id,
            };
          })
        );
      })
      .catch((error) => {
        // toast.error("Something went wrong!");
      });
  };
  useEffect(() => {
    let token = getToken();
    if (!token) {
      navigate("/auth/sign-in", { replace: true });
      window.location.reload();
    } else {
      // fetchRole();
      fetchTimeZone();
    }
  }, []);
  const base64String = ``;
  const meta = "data:application/msword;base64,";
  const data = meta;
  const dataURL = `https://view.officeapps.live.com/op/embed.aspx?src=${data}`;
  const fileType = "docx";
  return (
    <>
      {singleRoleData?.[0]?.api?.value?.add_api && (
        <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
          <CrerateSettlementReport
            allOrgDetails={allOrgDetails}
            fetchAllsettlement={fetchAllsettlement}
          />
        </div>
      )}
      <div className="mt-5">
        <SettlementTable
          pageSize={pageSize}
          // pageSize={0}
          setPageSize={setPageSize}
          tableData={paymentData}
          // tableData={[]}
          fetchAllsettlement={fetchAllsettlement}
          isLoading={isLoading}
          page={page}
          // page={0}
          setPage={setPage}
          totalpage={totalpage}
          // totalpage={0}
          totalItems={totalItems}
          currentPage={currentPage}
          roleData={singleRoleData}
          timeZone={timeZone}
          allCardType={allCardType}
          onValueChange={serchboxValueChange}
          allOrgDetails={allOrgDetails}
        />
      </div>
    </>
  );
};

export default API_tab;
