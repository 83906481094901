// import authRequest from "../utils/authRequest";
import authGraphQLRequest from "utils/authGraphQLRequest"

export const getAllGateways = (data?: any, searchTexts?: string): Promise<any> => {
  
  let queryString = "";

  if (data) {
    if (Array.isArray(data)) {
      // console.log("graphql if data is array");
      // console.log("array ::", { data });
      // If data is an array
      const assignedPaymentMethods = data.length > 0 ? JSON.stringify(data) : "";
      queryString = `{
      allGateways(assigned_payment_methods: ${assignedPaymentMethods}){
        id
        name
        is_active
        meta_info
        is_deleted
        payments
        refund
        subscription
        token
        payout
        authorization
        payin
        apm
        timezone
        card_types
        test_endpoint
        live_endpoint
        logo
      }
    }`;

    }
    if (typeof data === "string") {
      // console.log("graphql if data is string");

      // If data is a string
      const searchTerm = data ? `"${data}"` : '""';
      queryString = `{
      allGateways(search_term: ${searchTerm}){
        id
        name
        is_active
        meta_info
        is_deleted
        payments
        refund
        subscription
        token
        payout
        authorization
        payin
        apm
        timezone
        card_types
        test_endpoint
        live_endpoint
        logo
      }
    }`;

    }
  }
  else {
    queryString = `{
      allGateways{
        id
        name
        is_active
        meta_info
        is_deleted
        payments
        refund
        subscription
        token
        payout
        authorization
        payin
        apm
        timezone
        card_types
        test_endpoint
        live_endpoint
        logo
      }
    }`;
  }
  return new Promise((resolve, reject) => {
    console.log("graphql gateways all query :::", { queryString });
    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_gateway'
    )
      .then((responseData) => {
        resolve(responseData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};


export const getAllGatewaysByClient = (client_id: string): Promise<any> => {
  let queryString = `{
  getClientGateways(id: "${client_id}") {    
    client_gateway_id
    name
    auth_info
    transaction_pricing
    reserved_pricing
    cost
    bank_account_numbers
    payout_fees
    wallet_ids
    client_id   
    gateway_id
    is_live
    is_active
    to_convert
    new_currency
    payment_method
    whitelist_enabled
    velocity
    advanced_avs
  }
}
  `;
  return new Promise((resolve, reject) => {
    // console.log("graphql gateways all query :::", { queryString });
    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_get_client_gateways'
    )
      .then((responseData) => {
        resolve(responseData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// export const createGateway = (data: any): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/gateway",
//       method: "post",
//       data,
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const updateGateway = (data: any): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/gateway/" + data.id,
//       method: "put",
//       data,
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const getGateway = (data: { id: string }): Promise<any> => {
  let queryString = `{
  gateway(id:${data.id}){
    id
    name
    logo
    is_active
    meta_info
    is_deleted
    payments
    refund
    subscription
    token
    payout
    authorization
    payin
    apm
    timezone
    card_types
    test_endpoint
    live_endpoint
  }
}`;
  return new Promise((resolve, reject) => {
    // console.log("graphql gateways all query :::", { queryString });
    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_gateway'
    )
      .then((responseData) => {
        resolve(responseData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// export const deleteGateway = (id: string): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/gateway/" + id,
//       method: "delete",
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };


