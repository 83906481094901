import { ApolloClient, InMemoryCache, ApolloLink, HttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createBrowserHistory } from 'history';
import { clearAllCookie, getToken } from './auth'; // Adjust import paths as necessary

const history = createBrowserHistory();

// Function to create Apollo Client instance with dynamic endpoint
export function createGraphQLClient(endpoint: string): ApolloClient<any> {
  console.log("Creating GraphQL Client for endpoint:", endpoint);

  // HTTP link for making GraphQL requests
  const httpLink = new HttpLink({
    uri: endpoint, 
    fetchOptions: {
      mode: 'cors', // Ensure correct CORS settings
      credentials: 'include', // Adjust based on your authentication requirements
    },
  });

  // Middleware for adding the authentication token to headers
  const authLink = new ApolloLink((operation, forward) => {
    const token = getToken();

    if (!token) {
      clearAllCookie();
      history.push('/auth/sign-in');
      window.location.reload();
    }

    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
    });

    return forward(operation);
  });

  // Error handling link
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      const networkErrorObj = networkError as Error & { result?: { statusCode?: number } };

      console.error(`[Network error]: ${networkErrorObj.message}`, networkErrorObj);

      if (networkErrorObj.result?.statusCode === 401) {
        clearAllCookie();
        history.push('/auth/sign-in');
        window.location.reload();
      } else if (networkErrorObj.result?.statusCode === 422) {
        console.error('Validation error');
      }
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }
  });

  // Create and return Apollo Client instance
  return new ApolloClient({
    link: from([authLink, errorLink, httpLink]),
    cache: new InMemoryCache(),
  });
}
