import authRequest from "../utils/authRequest";

export const createUpdateCheckout = (data: any,client_id:any,client_secret:any): Promise<any> => {
  return new Promise((resolve, reject) => {
    authRequest({
      url: "/api/v1/checkout/create",
      method: "post",
      data,
      headers: {
        "client-id": client_id,
        "client-secret": client_secret,
      }
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCheckOutDetails = (id: string,client_id:any,client_secret:any): Promise<any> => {
  return new Promise((resolve, reject) => {
    authRequest({
      url: "/api/v1/checkout/" + id,
      method: "get",
      headers: {
        "client-id": client_id,
        "client-secret": client_secret,
      }
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCheckOutDetailsCheckoutPage = (id: string,client_id:any,client_secret:any): Promise<any> => {
  return new Promise((resolve, reject) => {
    authRequest({
      url: "/api/v1/checkout/payment-link/" + id,
      method: "get",
      // headers: {
      //   "client-id": client_id,
      //   "client-secret": client_secret,
      // },
      authRequired : false
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createPayment = (data: any,client_id : any,checkout_id:any,unique_payment_link_id:any,client_secret:any ): Promise<any> => {
  return new Promise((resolve, reject) => {
    authRequest({
      url: "/api/v1/checkout/payment-link/"+client_id+'/'+checkout_id+"/"+unique_payment_link_id,
      method: "post",
      data,
      // headers: {
      //   "client-id": client_id,
      //   "client-secret": client_secret,
      // },
      authRequired : false
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const generateCheckoutLink = (data: any,client_id:any,client_secret:any): Promise<any> => {
  return new Promise((resolve, reject) => {
    authRequest({
      // url: "/api/v1/checkout/generate-link",
      url: "/api/v1/checkout/payment-link/extend-expiry",
      method: "post",
      data,
      // headers: {
      //   "client-id": client_id,
      //   "client-secret": client_secret,
      // },
      authRequired : false
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const checkLink = (client_id : any,checkout_id:any,unique_payment_link_id:any,client_secret:any ): Promise<any> => {
  return new Promise((resolve, reject) => {
    authRequest({
      url: "/api/v1/checkout/payment-link/"+client_id+'/'+checkout_id+"/"+unique_payment_link_id,
      method: "get",
      // headers: {
      //   "client-id": client_id,
      //   "client-secret": client_secret,
      // },
      authRequired : false
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateCheckoutStatus = (data: any,client_id:any,client_secret:any): Promise<any> => {
  return new Promise((resolve, reject) => {
    authRequest({
      url: "/api/v1/checkout/status",
      method: "post",
      data,
      headers: {
        "client-id": client_id,
        "client-secret": client_secret,
      },
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createClient = (data: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    authRequest({
      url: "/api/v1/client",
      method: "post",
      data,
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateClientById = (data: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    authRequest({
      url: "/api/v1/client/" + data.client_id,
      method: "put",
      data,
    })
      .then(({ data }) => {
        //// console.log('data27', data)
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getClient = (id: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    authRequest({
      url: "/api/v1/client/" + id,
      method: "get",
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAllClients = (page?: number): Promise<any> => {
  console.log("getAllClients",{page})
  return new Promise((resolve, reject) => {
    authRequest({
      url: "/api/v1/client",
      method: "get",
      params: {
        page: page,
      }
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getClients = (params: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    console.log("getClients",{params})
    authRequest({
      url: "/api/v1/client",
      method: "get",
      params: params
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteClient = (id: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    authRequest({
      url: "/api/v1/client/" + id,
      method: "delete",
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getClientPaymentMethodDetails = (params: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    authRequest({
      url: "/api/v1/settlement/clients-payment-method",
      method: "get",
      params: params
    })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

