import { getToken } from './auth';
import { APIPath } from 'baserURL';

export default async function authGraphQLRequest<T = any>(
  query: string, 
  endpoint: string,
  variables: { [key: string]: any } = {},
  clientId?: string,
  clientSecret?: string
): Promise<T> {
  const token = getToken();

  const payload = {
    query: query,
    variables,
  };


  // Log the request details
  // console.log('GraphQL Request Payload:');
  // console.log('GraphQL Endpoint:', endpoint);
  // console.log('GraphQL Query:', payload.query);
  // console.log('GraphQL Variables:', payload.variables);
  // console.log('GraphQL Payload:', JSON.stringify(payload, null, 2));
 
  try {
    const response = await fetch(APIPath+endpoint, {
      method: 'POST',
      headers: {
        'Authorization': token ? `Bearer ${token}` : '',
        'Content-Type': 'application/json',
        'client-id' : clientId ? clientId : '',
        'client-secret' : clientSecret ? clientSecret : '',
      },  
      body: JSON.stringify(payload),
    });

    // console.log('HTTP Response Status:', response.status);
    // console.log('HTTP Response Headers:', response.headers);

    const result = await response.json();

    // console.log('GraphQL Response:', result);

    if (result) {
      return result as T;
    } else {
      throw new Error('Response structure is not as expected');
    }
  } catch (error: any) {
    console.error('GraphQL Request Error:', error);
    throw error;
  }
}
