import authRequest from "../utils/authRequest";
import authGraphQLRequest from "utils/authGraphQLRequest";

export const createClientGateway = (data: {
  client_id: string;
  method: string;
  gateway_id: string;
  body: any;
  to_convert?: any;
  new_currency?: any;
  reserved_pricing?: any;
  settlement?: any;
  payment_method?: any;
  cost?: any;
  velocity?: any;
  advanced_avs? : any;
  whitelist_enabled?: boolean

}): Promise<any> => {
  //@ts-ignore
  const { client_id, whitelist_enabled, gateway_id, is_live, body, method, to_convert, new_currency, reserved_pricing, settlement, payment_method, is_active, cost, velocity , advanced_avs} = data;
  let payload = {
    ...body,
    is_live: is_live,
    to_convert,
    new_currency,
    reserved_pricing,
    settlement,
    payment_method,
    is_active: is_active,
    cost,
    velocity,
    advanced_avs,
    whitelist_enabled
  }
  return new Promise((resolve, reject) => {
    if (method == "post") {
      let queryString = `mutation {
        createClientGateway(
          client_id: "${client_id}",
          gateway_id: "${gateway_id}",
          auth_info: "${JSON.stringify(body.auth_info).replace(/\\/g, '\\\\').replace(/"/g, '\\"')}",
          is_live: ${is_live},
          to_convert: ${to_convert},
          new_currency: "${new_currency}",
          reserved_pricing: "${JSON.stringify(reserved_pricing).replace(/\\/g, '\\\\').replace(/"/g, '\\"')}",
          settlement: "${JSON.stringify(settlement).replace(/\\/g, '\\\\').replace(/"/g, '\\"')}",
          payment_method: "${JSON.stringify(payment_method).replace(/\\/g, '\\\\').replace(/"/g, '\\"')}",
          is_active: ${is_active},
          cost: "${JSON.stringify(cost).replace(/\\/g, '\\\\').replace(/"/g, '\\"')}",
          whitelist_enabled: ${whitelist_enabled},
          velocity: "${JSON.stringify(velocity).replace(/\\/g, '\\\\').replace(/"/g, '\\"')}",
          advanced_avs : "${JSON.stringify(advanced_avs).replace(/\\/g, '\\\\').replace(/"/g, '\\"')}",
        ) {
          clientGateways {
            id
            client_id
            gateway_id
            auth_info
            reserved_pricing
            settlement
            payment_method
            cost
            is_live
            is_active
            new_currency
            to_convert
            whitelist_enabled
          }
        }
      }`;
      
      authGraphQLRequest<any>(
        queryString,
        '/api/v1/graphql_create_client_gateway'
      )
        .then((responseData) => {
          console.log('promise api call graphql response createClientGateway ::', { responseData });
          resolve(responseData);
        })
        .catch((err) => {
          console.log('promise api call graphql err user createClientGateway ::', { err });
          reject(err);
        });
    }
    if (method == "put") {
      let queryString = `mutation {
        updateClientGateway(
          client_id: "${client_id}",
          gateway_id: "${gateway_id}",
          auth_info: "${JSON.stringify(body.auth_info).replace(/\\/g, '\\\\').replace(/"/g, '\\"')}",
          is_live: ${is_live},
          to_convert: ${to_convert},
          new_currency: "${new_currency}",
          reserved_pricing: "${JSON.stringify(reserved_pricing).replace(/\\/g, '\\\\').replace(/"/g, '\\"')}",
          settlement: "${JSON.stringify(settlement).replace(/\\/g, '\\\\').replace(/"/g, '\\"')}",
          payment_method: "${JSON.stringify(payment_method).replace(/\\/g, '\\\\').replace(/"/g, '\\"')}",
          is_active: ${is_active},
          cost: "${JSON.stringify(cost).replace(/\\/g, '\\\\').replace(/"/g, '\\"')}",
          whitelist_enabled: ${whitelist_enabled},
          velocity: "${JSON.stringify(velocity).replace(/\\/g, '\\\\').replace(/"/g, '\\"')}",
          advanced_avs : "${JSON.stringify(advanced_avs).replace(/\\/g, '\\\\').replace(/"/g, '\\"')}",
        ) {
          clientGateways {
            id
            client_id
            gateway_id
            auth_info
            reserved_pricing
            settlement
            payment_method
            cost
            is_live
            is_active
            new_currency
            to_convert
            whitelist_enabled
          }
        }
      }`;
      
      authGraphQLRequest<any>(
        queryString,
        '/api/v1/graphql_update_client_gateway'
      )
        .then((responseData) => {
          console.log('promise api call graphql response createClientGateway ::', { responseData });
          resolve(responseData);
        })
        .catch((err) => {
          console.log('promise api call graphql err user createClientGateway ::', { err });
          reject(err);
        });
    }
  });
};


// export const enableGateway = (data: { id: string; }): Promise<any> => {

//   return new Promise((resolve, reject) => {
//     // console.log('id29', data)
//     authRequest({
//       url: `/api/v1/client/${data?.id}`,
//       method: "put",
//       data
//     })
//       .then(({ data }) => {
//         // console.log('data34', data)
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const updateClient = (data: {
//   id: string;
//   name: string;
//   descrption?: string;
//   bot_name?: string;

// }): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/client/update",
//       method: "post",
//       data,
//     })
//       .then(({ data }) => {
//         // console.log('data', data)
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const deleteClient = (id: string): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/client/" + id,
//       method: "delete",
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const deleteClientGateway = (data: any): Promise<any> => {
  let queryString = `
  mutation {
  deleteClientGateway(client_id: "${data.client_id}", gateway_id: "${data.gateway_id}") {
    status
    message
  }
}

  `;
  return new Promise((resolve, reject) => {
    // console.log("graphql gateways all query :::", { queryString });
    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_delete_client_gateway'
    )
      .then((responseData) => {
        resolve(responseData);
      })
      .catch((err) => {
        reject(err);
      });
  });
};



// export const getEnabledSmartRoute = (id: String): Promise<any> => {
//   // console.log('id', id)	
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: `/api/v1/client/${id}`,
//       method: "get",
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };


// export const getGatewayForClient = (id: String, gateway_id: String): Promise<any> => {
//   // console.log('id', id)	
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: `/api/v1/client/${id}/gateway/${gateway_id}`,
//       method: "get",
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const verifyWalletAddress = (data: {
//   id?: String;
//   gateway_id?: String;
//   password?: any;
//   otp?: any;
//   wallet_id?: any
// }): Promise<any> => {
//   // console.log('id', id)	
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: `/api/v1/client/${data?.id}/gateway/${data?.gateway_id}/ewallet/verify`,
//       method: "post",
//       data
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };