// import authRequest from "../utils/authRequest";
import authGraphQLRequest from "utils/authGraphQLRequest";

// export const createClient = (data: any): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/client",
//       method: "post",
//       data,
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const createClient = (data: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let { name, email, phone, org_id, assigned_payment_methods, description, website, postal_code, state, country, address, city } = data;
    let queryString = `mutation {
        createClient(
            name: "${name}",
            email: "${email}",
            phone: "${phone}",
            org_id: "${org_id}",
            assigned_payment_methods: [${assigned_payment_methods.map((id : any) => `"${id}"`).join(", ")}],
            description:"${description}",
            website : "${website}",
            postal_code : "${postal_code}",
            state : "${state}",
            country : "${country}",
            address : "${address}",
            city : "${city}",
        ) {
            client {
                name
                email
                phone
                org_id
                client_id
                assigned_payment_methods
                description
                website
                postal_code
                state
                country
                address
                city
            }
        }
    }
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_create_client'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response client by Id ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err client by Id ::', { err });
        reject(err);
      });
  });
};

export const updateClientById = (data: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let { client_id, org_id, client_secret, name, description, website, email, phone, address, city, state, country, postal_code, isSmartRouteEnabled, sequential_algorithm, assigned_payment_methods } = data;
    
    let queryString = `mutation {
      updateClient(
        id: "${client_id}",
        org_id: "${org_id}",
        client_secret: "${client_secret}",
        name: "${name}",
        description: "${description}",
        website: "${website}",
        email: "${email}",
        phone: "${phone}",
        address: "${address}",
        city: "${city}",
        state: "${state}",
        country: "${country}",
        postal_code: "${postal_code}",
        isSmartRouteEnabled: ${isSmartRouteEnabled},
        sequential_algorithm: ${sequential_algorithm ?? 1},
        assigned_payment_methods:  [${assigned_payment_methods.map((id : any) => `"${id}"`).join(", ")}],
      ) {
        client {
          client_id
          client_secret
          name
          email
          phone
          org_id
          assigned_payment_methods
          description
          website
          postal_code
          state
          country
          address
          city
          isSmartRouteEnabled
          sequential_algorithm

        }
      }
    }
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_update_client'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response client by Id ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err client by Id ::', { err });
        reject(err);
      });
  });
}
// export const updateClientById = (data: any): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/client/" + data.client_id,
//       method: "put",
//       data,
//     })
//       .then(({ data }) => {
//         //console.log('data27', data)
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const getClient = (id: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `{
      client(id: "${id}") {
        client_id
        org_id
        org_name
        client_secret
        name
        description
        website
        email
        phone
        address
        city
        state
        country
        postal_code
        isSmartRouteEnabled
        sequential_algorithm
        sequential_priority_check
        assigned_payment_methods
        client_gateway_active
        created_at
      }
    }
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_client'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response client by Id ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err client by Id ::', { err });
        reject(err);
      });
  });
};

// export const getAllClients = (page?: number): Promise<any> => {
//   console.log("getAllClients",{page})
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/client",
//       method: "get",
//       params: {
//         page: page,
//       }
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

export const getClients = ({ page, per_page, searchTexts }: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `{ 
      allClients(page: ${page}, per_page: ${per_page}, searchTexts: "${searchTexts}") {
        clients{
          client_id
          org_name
          client_secret
          name
          description
          website
          email
          phone
          address
          city
          state
          country
          postal_code
          isSmartRouteEnabled
          sequential_algorithm
          sequential_priority_check
          assigned_payment_methods
          client_gateway_active
          created_at
          checkout_id
        }
      }
    }`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_client'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response ::', { responseData });
          resolve(responseData);
      })
      .catch((err) => {
        // console.log('promise api call graphql err ::', { err });
        reject(err);
      });
  });
};

export const deleteClient = (id:any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `
    mutation {
      deleteClient(id: "${id}") {
        status
        message
      }
    }
      `;
        authGraphQLRequest<any>(
          queryString,
          '/api/v1/graphql_delete_client'
        )
          .then((responseData) => {
            resolve(responseData);
          })
          .catch((err) => {
            reject(err);
          });
  }); 
}

// export const deleteClient = (id: string): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/client/" + id,
//       method: "delete",
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const getClientPaymentMethodDetails = (params: any): Promise<any> => {
//   return new Promise((resolve, reject) => {
//     authRequest({
//       url: "/api/v1/settlement/clients-payment-method",
//       method: "get",
//       params: params
//     })
//       .then(({ data }) => {
//         resolve(data);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

