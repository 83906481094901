import authGraphQLRequest from "utils/authGraphQLRequest";

export const getAllTabs = (type: string, params: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let { org_ids, client_ids, time_frame, start_date, end_date, card_type } = params;

    let queryString = `{
  dashboardDetails(
    ${org_ids ? `org_ids: "${org_ids}",` : ""}
    ${client_ids ? `client_ids: "${client_ids}",` : ""}
    ${time_frame ? `time_frame: "${time_frame}",` : ""}
    ${start_date ? `start_date: "${start_date}",` : ""}
    ${end_date ? `end_date: "${end_date}",` : ""}
    ${card_type ? `card_type: "${card_type}",` : ""}
    is_live: "${type}"
  ) {
    totalTransactions
    transactionsApproved
    transactionsDeclined
    transactionsRefunded
    transactionsErrored
  }
}
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_dashboard_details'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response getAllTabs ::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err user getAllTabs ::', { err });
        reject(err);
      });
  });
};

export const getTransactionByGateway = (type: string, live: string, params: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let { org_ids, client_ids, time_frame, start_date, end_date, card_type } = params;

    let apiURL = "";
    let queryString = "";

    if (type == 'approved') {

      apiURL = '/api/v1/graphql_dashboard_approved_details';

      queryString = `{
      gatewayPaymentApprovedDetails(
        ${time_frame ? `time_frame: "${time_frame}",` : ""}
        ${start_date ? `start_date: "${start_date}",` : ""}
        ${end_date ? `end_date: "${end_date}",` : ""}
        ${org_ids ? `org_ids: "${org_ids}",` : ""}
        ${client_ids ? `client_ids: "${client_ids}",` : ""}
        ${card_type ? `card_type: "${card_type}",` : ""}
        is_live: "${live}"
      )  {
        data
      }
    }
      `;
    }

    if (type == 'decline') {

      apiURL = '/api/v1/graphql_dashboard_decline_details';

      queryString = `{
  gatewayPaymentDeclineDetails(
    ${time_frame ? `time_frame: "${time_frame}",` : ""}
    ${start_date ? `start_date: "${start_date}",` : ""}
    ${end_date ? `end_date: "${end_date}",` : ""}
    ${org_ids ? `org_ids: "${org_ids}",` : ""}
    ${client_ids ? `client_ids: "${client_ids}",` : ""}
    ${card_type ? `card_type: "${card_type}",` : ""}
    is_live: "${live}"
  )  {
    data
  }
}
  `;
    }


    authGraphQLRequest<any>(
      queryString,
      apiURL
    )
      .then((responseData) => {
        // console.log('promise api call graphql response getTransactionByGateway ::', { responseData });
        resolve(responseData);
        // resolve({});
      })
      .catch((err) => {
        console.log('promise api call graphql err getTransactionByGateway ::', { err });
        reject(err);
      });
  });
};

export const getTransactionByAnalysis = (type: string, live: string, params: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let { org_ids, client_ids, time_frame, start_date, end_date, card_type } = params;

    let queryString = `{
  transactionDetails(
    ${org_ids ? `org_ids: "${org_ids}",` : ""}
    ${client_ids ? `client_ids: "${client_ids}",` : ""}
    ${time_frame ? `time_frame: "${time_frame}",` : ""}
    ${start_date ? `start_date: "${start_date}",` : ""}
    ${end_date ? `end_date: "${end_date}",` : ""}
    ${card_type ? `card_type: "${card_type}",` : ""}
    is_live: "${live}"
  )  {
    Approved 
    Declined 
  }
}
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_dashboard_transaction_details'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response getTransactionByAnalysis ::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err getTransactionByAnalysis ::', { err });
        reject(err);
      });
  });
};

export const getTransactionByCurrency = (
  type: string,
  params: any,
  live: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    let { org_ids, client_ids, time_frame, start_date, end_date, card_type } = params;

    let apiURL = "";
    let queryString = "";

    if (type == 'approved') {

      apiURL = '/api/v1/graphql_dashboard_approved_currency';

      queryString = `{
  paymentApprovedCurrencyDetails(
    ${org_ids ? `org_ids: "${org_ids}",` : ""}
    ${client_ids ? `client_ids: "${client_ids}",` : ""}
    ${time_frame ? `time_frame: "${time_frame}",` : ""}
    ${start_date ? `start_date: "${start_date}",` : ""}
    ${end_date ? `end_date: "${end_date}",` : ""}
    ${card_type ? `card_type: "${card_type}",` : ""}
    is_live: "${live}"
  )  {
    data
  }
}
   `;
    }

    if (type == 'decline') {

      apiURL = '/api/v1/graphql_dashboard_decline_currency';

      queryString = `{
  paymentDeclineCurrencyDetails(
    ${org_ids ? `org_ids: "${org_ids}",` : ""}
    ${client_ids ? `client_ids: "${client_ids}",` : ""}
    ${time_frame ? `time_frame: "${time_frame}",` : ""}
    ${start_date ? `start_date: "${start_date}",` : ""}
    ${end_date ? `end_date: "${end_date}",` : ""}
    ${card_type ? `card_type: "${card_type}",` : ""}
    is_live: "${live}"
  )  {
    data
  }
}
  `;
    }


    authGraphQLRequest<any>(
      queryString,
      apiURL
    )
      .then((responseData) => {
        // console.log('promise api call graphql response getTransactionByCurrency ::', { responseData });
        // resolve(responseData);
        resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err getTransactionByCurrency ::', { err });
        reject(err);
      });
  });
};

export const getWawechratData = (params: any, type: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    let { org_ids, client_ids, time_frame, start_date, end_date, card_type } = params;

    let queryString = `{
  waveChartDetails(
    card_type : "${card_type}",
    client_ids : "${client_ids}",
    org_ids : "${org_ids}",
    ${time_frame ? `time_frame: "${time_frame}",` : ""}
    ${start_date ? `start_date: "${start_date}",` : ""}
    ${end_date ? `end_date: "${end_date}",` : ""}
    is_live: "${type}"
  ) {
    APPROVED 
    DECLINED 
    grouped_by
    total_transactions
    transactions_approved
    transactions_declined
  }
}
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_dashboard_wave_details'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response getWawechratData ::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err getWawechratData ::', { err });
        reject(err);
      });
  });
};

export const getOrganizationDetails = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    let queryString = `{
  organizations {
    id
    name
    clients {
      id
      name
    }
  }
}
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_dashboard_Client_org'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response getOrganizationDetails ::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err getOrganizationDetails ::', { err });
        reject(err);
      });
  });
};

export const getClientDetails = (params: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    let { org_ids } = params;
    let queryString = `{
  clients(org_ids: "${org_ids}") {
    id
    name
  }
}
`;

    authGraphQLRequest<any>(
      queryString,
      '/api/v1/graphql_dashboard_Client'
    )
      .then((responseData) => {
        // console.log('promise api call graphql response getClientDetails::', { responseData });
        resolve(responseData);
      })
      .catch((err) => {
        console.log('promise api call graphql err user getClientDetails ::', { err });
        reject(err);
      });
  });
};