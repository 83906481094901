import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import InputField from "components/fields/InputField";
import Card from "components/card";
import DivLoader from "components/divloader/DivLoader";
import React, { useState, useEffect, useContext } from "react";
import {
  MdAdd,
  MdCancel,
  MdCheck,
  MdCheckCircle,
  MdError,
  MdExpand,
  MdFileCopy,
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowRight,
  MdOutlineCancel,
  MdOutlineReplayCircleFilled,
  MdRemove,
  MdTimer,
} from "react-icons/md";
import { GatewayTypes } from "views/admin/clients/components/CreateClientGatewaysModal";
import { TruncateCopy } from "views/admin/organizations/components/CreateOrgModal";
import { PaymentRowObj } from "..";
import { convertToFloat } from "utils/formatNumber";
import { BsReceipt } from "react-icons/bs";
import { ClientContext } from "ClientProvider";
import { DownloadCSV, getLive } from "api/payments";
import {  getStatusHistory } from "api/graphQL_payments";
import { toast } from "react-hot-toast";
import { RiRotateLockFill } from "react-icons/ri";
import { FaDownload } from "react-icons/fa";
import ShortTruncateCopy from "components/common/ShortTruncateCopy";
import Searchbox from "components/fields/Searchbox";
import { getImage } from "utils/commonFunction";
import Pagination from "components/pagination";
import visa from "assets/svg/card_type/visa.svg";
import MasterCard from "assets/svg/card_type/mastercard.svg";
import Discover from "assets/svg/card_type/discover.svg";
import Amex from "assets/svg/card_type/amex.svg";
import MaestroCard from "assets/svg/card_type/maestro.svg";
import DinersClub from "assets/svg/card_type/dinersclub.svg";
import JCB from "assets/svg/card_type/jcb.svg";
import UnionPay from "assets/svg/card_type/unionpay.svg";
import TooltipHorizon from "components/tooltip";
import { MdChangeCircle } from "react-icons/md";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from "@chakra-ui/popover";
import { Portal } from "@chakra-ui/portal";
import moment from "moment";
import PopoverHorizon from "components/popover";
import {
  UncontrolledPopover,
  PopoverHeader as PopoverHeaderR,
  PopoverBody as PopoverBodyR,
  Button as ButtonR,
} from "reactstrap";
import InfoModal from "./InfoModal";
import StatusRender from "views/admin/payments/components/StatusRender";

function LogList(props: {
  tableData?: any;
  fetchPayments?: () => void;
  filterData?: () => void;
  isLoading?: boolean;
  page?: number;
  setPage?: any;
  totalpage?: number;
  totalItems?: number;
  currentPage?: number;
  clients?: any[];
  allGateways?: any[];
  allCardType?: any[];
  curretClient?: string;
  setCurrentClient?: any;
  pageSize?: number;
  setPageSize?: any;
  roleData?: any;
  filter?: any;
  setFilter?: (e: any) => void;
  onValueChange?: (value: string) => void;
  selectedOptions?: string;
}) {
  const {
    tableData,
    page,
    setPage,
    currentPage,
    totalpage,
    pageSize,
    setPageSize,
    selectedOptions,
  } = props;
  let defaultData = tableData;
  const [{ pageIndex }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize,
  });
  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );
  const columns_organization = [
    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">DATE</p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {info.getValue()}
              </p>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          user name
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  {info?.row?.original?.user_name || "-"}
                </p>
              </div>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          action type
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  {info?.row?.original?.action_type || "-"}
                </p>
              </div>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("organization_id", {
      id: "organization_id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          organization id
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  <ShortTruncateCopy
                    info={info?.row?.original?.organization_id}
                    slice={13}
                    showCopy={info?.getValue() !== "-"}
                  />
                </p>
              </div>
            )}
          </>
        );
      },
    }),
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          payload
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  <InfoModal
                    info={info?.row?.original?.payload}
                    heading="Payload"
                  />
                </p>
              </div>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          response
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  <InfoModal info={info.getValue()} />
                </p>
              </div>
            )}
          </>
        );
      },
    }),
  ]; // eslint-disable-next-line

  const columns_payments = [
    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">DATE</p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {info.getValue()}
              </p>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          user name
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  {info?.row?.original?.user_name || "-"}
                </p>
              </div>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          action type
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  {info?.row?.original?.action_type || "-"}
                </p>
              </div>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("payment_id", {
      id: "payment_id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          Interanl Id
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  <ShortTruncateCopy
                    info={info?.row?.original?.payment_id}
                    slice={13}
                    showCopy={info?.getValue() !== "-"}
                  />
                </p>
              </div>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          Payment Status
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="flex items-center text-sm font-bold text-navy-700 dark:text-white">
                  <StatusRender
                    status={info?.row?.original?.response?.status}
                    value={info?.row?.original?.response?.status}
                  />
                </p>
              </div>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          User Info
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  <InfoModal
                    info={info?.row?.original?.user_data}
                    heading="User Info"
                  />
                </p>
              </div>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          Gateway payload
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  <InfoModal
                    info={info?.row?.original?.gateway_payload}
                    heading="Gateway Payload"
                  />
                </p>
              </div>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          Gateway response
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  <InfoModal
                    info={info?.row?.original?.gatewat_response}
                    heading="Gateway Response"
                  />
                </p>
              </div>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          response
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  <InfoModal
                    info={
                      info?.row?.original?.response ||
                      info?.row?.original?.error_response
                    }
                    heading="Response"
                  />
                </p>
              </div>
            )}
          </>
        );
      },
    }),
  ]; // eslint-disable-next-line

  const columns_client = [
    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">DATE</p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {info.getValue()}
              </p>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          user name
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  {info?.row?.original?.user_name || "-"}
                </p>
              </div>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          action type
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  {info?.row?.original?.action_type || "-"}
                </p>
              </div>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("client_id", {
      id: "client_id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          client id
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  <ShortTruncateCopy
                    info={info?.row?.original?.client_id}
                    slice={13}
                    showCopy={info?.getValue() !== "-"}
                  />
                </p>
              </div>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          payload
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  <InfoModal
                    info={info?.row?.original?.payload}
                    heading="Payload"
                  />
                </p>
              </div>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="pl-2 text-sm font-bold text-gray-900 dark:text-white">
          response
        </p>
      ),
      cell: (info: any) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <div className="flex items-center pl-2">
                <p className="text-sm font-bold text-navy-700 dark:text-white">
                  <InfoModal info={info.getValue()} />
                </p>
              </div>
            )}
          </>
        );
      },
    }),
  ]; // eslint-disable-next-line
  const [data, setData] = React.useState(() => [...defaultData]);
  React.useEffect(() => {
    setData(tableData);
  }, [tableData]);
  const getTableColumns = (dropDownOptions: string) => {
    switch (dropDownOptions) {
      case "payment":
        return columns_payments;
      case "organization":
        return columns_organization;
      default:
        return columns_client;
    }
  };
  const table = useReactTable({
    data,
    columns: getTableColumns(selectedOptions),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
  });

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6 mb-6"}>
      <header className="relative flex flex-wrap items-center justify-between pt-5">
        <div className="block w-full justify-between gap-5 md:flex lg:flex">
          <div className="flex items-center gap-5">
            <div className="text-xl font-bold uppercase text-navy-700 dark:text-white">
              {selectedOptions}
              {"-"}Logs
            </div>
          </div>
        </div>
      </header>

      <div className="relative mt-4 overflow-x-auto overflow-x-auto shadow-md scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-h-1.5 sm:rounded-lg">
        {props?.isLoading ? (
          <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
        ) : (
          <table className="w-full w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              {table?.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="!border-px !border-gray-400"
                >
                  {headerGroup.headers?.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        onClick={header.column.getToggleSortingHandler()}
                        className="cursor-pointer border-b-[1px] border-gray-200 pb-2  pt-4 text-start"
                      >
                        <div className="items-center justify-between px-2 text-xs text-gray-200">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows?.length > 0 ? (
                table.getRowModel().rows.map((row) => {
                  return (
                    <tr
                      key={row.id}
                      className={`border-b hover:bg-gray-50 dark:border-gray-700  dark:bg-gray-800 ${
                        row.getIsExpanded() ? "" : ""
                      } ${!row.getCanExpand() ? "" : ""} `}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td
                            key={cell.id}
                            className={`border-white/0 px-2 py-3 ${
                              cell.column.id === "payment_id" ? "" : ""
                            }`}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>
                    <p className="p-4 text-center">No records found.</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>

      <Pagination
        setPage={setPage}
        page={page}
        totalpage={totalpage}
        currentPage={currentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        arraySize={[50, 100, 200]}
      />
    </Card>
  );
}

export default LogList;
const columnHelper = createColumnHelper<PaymentRowObj>();
