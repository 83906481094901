import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import "./index.css";
import App from "./App";
import { ClientProvider } from "ClientProvider";
import { ApolloProvider } from "@apollo/client";
import { createGraphQLClient } from "./utils/apolloClient"; // Adjusted import

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const endpoint = 'default-endpoint'; // Set your default endpoint or logic to determine it
const graphqlClient = createGraphQLClient(endpoint); // Use the factory function

root.render(
  <ClientProvider>
    <ApolloProvider client={graphqlClient}>
      <BrowserRouter>
        <div style={{ position: "absolute", top: "4rem" }}>
          <Toaster position="top-center" containerClassName="font-nunito" />
        </div>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </ClientProvider>
);
